import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DataProxy } from 'apollo-cache';
import { MutationFn } from 'react-apollo';

import {
  CartItem,
  CartItemInput,
  CartItemProductInput,
  Mutation as MutationOrderResults,
  MutationupdateCartArgs,
  RequestCompleteQuoteOutput,
} from 'inkp-order-sdk/types.g';
import { Product, ProductColor, ProductSize } from 'inkp-product-sdk/types.g';
import { stringifyProductItemId, prettyProductSize, PRODUCT_CATEGORY_SCOPES } from 'inkp-product-sdk';
import { getCartProductTotalQuantity } from 'inkp-order-sdk/order';
import PriceDisplayer from 'inkp-components/dist/Components/PriceDisplayer';
import Image from 'inkp-components/dist/Components/Image';
import Loading from 'inkp-components/dist/Components/Loading';
import Popover from 'inkp-components/dist/Components/Popover';
import { computeCoordinates } from 'inkp-components/dist/hooks/usePopoverAnchor';

import ProductQuantitySelector from '../ProductQuantitySelector';
import { CartProductWithQuotes } from '../../interfaces/CartCheckout';
import { CartProduct } from '../../interfaces/CartProduct';
import { SIDES_BY_ORDER } from '../../util/Product';
import {
  CART_PRODUCT_ERRORS,
  MUTATION_UPDATE_CART,
  MutationUpdateCart,
  QUERY_REQUEST_CART_QUOTE,
  updateCurrentCart,
  getCartProductErrors,
  getCartProductWarnings,
} from '../../util/Checkout';

interface Props extends RouteComponentProps {
  cartId: string;
  cartItems: CartItem[];
  cartProduct: CartProduct;
  cartProductWithQuotes?: CartProductWithQuotes;
  error?: { code: CART_PRODUCT_ERRORS; data?: any };
  showEditQty?: boolean;
  popoverParentId?: string;
  onRemoveItemClick?: () => void;
  showRemoveItem?: boolean;
}

type CartItemTab = 'size' | 'note' | null;
interface State {
  cartItemTab: CartItemTab;
  showSizeGuide: boolean;
  showQuantities: boolean;
  sizesQuantities: { [id: string]: number };
  hoverImageIndex: number;
}

class CartProductItem extends React.Component<Props, State> {
  readonly state: State;
  imageRefs: React.RefObject<HTMLImageElement>[];

  constructor(props: Props) {
    super(props);
    const { cartProduct } = props;
    this.imageRefs = _.map(_.range(_.size(cartProduct.mockups)), () => {
      return React.createRef<HTMLImageElement>();
    });

    this.state = {
      cartItemTab: null,
      showSizeGuide: false,
      showQuantities: false,
      sizesQuantities: {
        ...cartProduct.sizesQuantities,
      },
      hoverImageIndex: -1,
    };
  }

  private setHoverImageIndex(index: number) {
    this.setState(() => {
      return { hoverImageIndex: index };
    });
  }

  private onRemoveItemClick = (updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) => (
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    const newCartItems: CartItemInput[] = this.getRemainingCartItems();
    const { cartId } = this.props;
    return updateCart({
      variables: {
        id: cartId,
        data: {
          items: newCartItems,
        },
      },
      update: (cache: DataProxy, { data: { updateCart } }: any) => {
        updateCurrentCart(cache, updateCart);
      },
      refetchQueries: (mutationResult) => {
        return [
          {
            query: QUERY_REQUEST_CART_QUOTE,
            variables: { cartId },
          },
        ];
      },
      awaitRefetchQueries: true,
    });
  };

  private getRemainingCartItems = (): CartItemInput[] => {
    const {
      cartItems: originalCartItems,
      cartProduct: { cartItemsIndexes },
    } = this.props;
    let cartItems: CartItemInput[] = originalCartItems.slice();
    cartItems.splice(cartItemsIndexes[0], cartItemsIndexes.length);
    cartItems = cartItems.map(
      (cartItem: CartItem): CartItemInput => {
        const {
          designId,
          product: { productItemId, quantity, mockupUrl, mockups },
        } = cartItem;
        return {
          designId,
          product: {
            productItemId,
            quantity,
            mockupUrl,
            mockups,
          },
        };
      }
    );
    return cartItems;
  };

  private getUpdatedCartItems = (): CartItemInput[] => {
    const {
      cartItems: originalCartItems,
      cartProduct: { id, mockupUrl, mockups, cartItemsIndexes },
    } = this.props;
    const { productId, designId, colorId } = JSON.parse(id);
    const { sizesQuantities } = this.state;
    const updatedCartItemsForCartProduct: CartItemInput[] = Object.keys(sizesQuantities)
      .map((size: string, index: number) => {
        const productItemId: string = stringifyProductItemId(productId, colorId, size);
        const quantity: number = sizesQuantities[size];
        const cartItemProductInput: CartItemProductInput = {
          productItemId,
          quantity,
          mockupUrl,
          mockups,
        };
        return {
          designId,
          product: cartItemProductInput,
        };
      })
      .filter((updatedCartItemForCartProduct: CartItemInput) => updatedCartItemForCartProduct.product.quantity > 0);
    const cartItems: CartItemInput[] = originalCartItems.map((cartItem: CartItem) => {
      const {
        designId,
        product: { productItemId, quantity, mockupUrl, mockups },
      } = cartItem;
      return {
        designId,
        product: {
          productItemId,
          quantity,
          mockupUrl,
          mockups,
        },
      };
    });
    const startIndex: number = cartItemsIndexes[0];
    const cartItemsForCartProductCount: number = cartItemsIndexes.length;
    cartItems.splice(startIndex, cartItemsForCartProductCount, ...updatedCartItemsForCartProduct);
    return cartItems;
  };

  private updateCart = (updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) => {
    const { cartId } = this.props;
    const newCartItems: CartItemInput[] = this.getUpdatedCartItems();
    return updateCart({
      variables: {
        id: cartId,
        data: {
          items: newCartItems,
        },
      },
      update: (cache: DataProxy, { data }: any) => {
        updateCurrentCart(cache, data.updateCart);
        this.setState({
          cartItemTab: null,
        });
      },
      refetchQueries: (mutationResult) => {
        return [
          {
            query: QUERY_REQUEST_CART_QUOTE,
            variables: { cartId },
          },
        ];
      },
      awaitRefetchQueries: true,
    });
  };

  private debouncedUpdateCart = _.debounce(this.updateCart, 150);

  private changeSize = (updateCart: MutationFn<MutationOrderResults, MutationupdateCartArgs>) => (
    size: string,
    qty: number
  ) => {
    const { sizesQuantities } = this.state;
    this.setState({
      sizesQuantities: {
        ...sizesQuantities,
        [size]: qty,
      },
    });
    this.debouncedUpdateCart(updateCart);
  };

  render() {
    const {
      cartProduct,
      cartProductWithQuotes,
      error,
      showEditQty,
      popoverParentId = 'root',
      showRemoveItem,
    } = this.props;
    const { sizesQuantities, hoverImageIndex, showQuantities } = this.state;

    const product: Product = cartProduct.product;
    const productName: string = product.name;
    const productBrand: string = (
      product.categories.find((category) => {
        return category.scope === PRODUCT_CATEGORY_SCOPES.BRAND;
      }) || { name: '' }
    ).name;
    const colorName: string = cartProduct.colorId; // color Id is just the name in lower case
    const mockupUrl: string = cartProduct.mockupUrl;
    const mockups =
      _.sortBy(cartProduct.mockups, (mockup) => {
        return SIDES_BY_ORDER.indexOf(mockup.side);
      }) || [];
    const totalQuantity: number = getCartProductTotalQuantity(cartProduct);
    const preCheckoutSubtotal: number = cartProductWithQuotes
      ? cartProductWithQuotes.quotes.reduce((currentSubtotal: number, currentQuote: RequestCompleteQuoteOutput) => {
          const {
            amounts: { subtotal, shipping, discount },
          } = currentQuote;
          const cartQuoteWithoutSubtotal = subtotal - shipping + discount; // subtract shipping (normally added), add back discount (normally subtracted)
          return currentSubtotal + cartQuoteWithoutSubtotal;
        }, 0)
      : 0;
    const pricePerPiece: number = totalQuantity > 0 ? Math.floor(preCheckoutSubtotal / totalQuantity) : 0;
    const currentColor: ProductColor = product.colors.find((color: ProductColor) => {
      return color.name.toLowerCase() === colorName.toLowerCase();
    }) as ProductColor;

    const availableSizes = currentColor.sizesObjects as ProductSize[];
    const sizeDisplays = _.compact(
      availableSizes.map((availableSize) => {
        if (!cartProduct.sizesQuantities[availableSize.name]) return null;
        return `${prettyProductSize(availableSize.name)}-${cartProduct.sizesQuantities[availableSize.name]}`;
      })
    );

    const anchorData = _.get(this.imageRefs, `${hoverImageIndex}.current`)
      ? computeCoordinates(this.imageRefs[hoverImageIndex], popoverParentId)
      : null;

    return (
      <MutationUpdateCart mutation={MUTATION_UPDATE_CART}>
        {(updateCart, { error: updateCartError, loading, data }) => {
          return (
            <div className="w-full fs-ms p-relative">
              <style jsx={true}>
                {`
                  .fs-18 {
                    font-size: 18px;
                  }
                  .fs-14 {
                    font-size: 14px;
                  }
                `}
              </style>
              <div
                className={classnames(['product-list-details w-full flex p-relative bgc-white fs-xs'], {
                  br: !error,
                  brt: error,
                })}
              >
                <div className="lg:w-auto">
                  {mockups.length <= 1 ? (
                    <div
                      ref={this.imageRefs[0]}
                      className="image-container br"
                      style={{ height: '112px', width: '92px' }}
                      onMouseEnter={() => {
                        this.setHoverImageIndex(0);
                      }}
                      onMouseLeave={() => {
                        this.setHoverImageIndex(-1);
                      }}
                    >
                      <Image src={mockupUrl} className="br h-full" />
                    </div>
                  ) : (
                    <div>
                      <div
                        ref={this.imageRefs[0]}
                        className="image-container br"
                        style={{ height: '112px', width: '92px' }}
                        onMouseEnter={() => {
                          this.setHoverImageIndex(0);
                        }}
                        onMouseLeave={() => {
                          this.setHoverImageIndex(-1);
                        }}
                      >
                        <Image src={mockups[0].mockupUrl} className="br h-full" />
                      </div>
                      <div className="-mx-2px flex mt-p25">
                        {mockups.map((mockup, mockupIndex) => {
                          if (mockupIndex > 0) {
                            return (
                              <div
                                key={`${mockup.mockupUrl}`}
                                ref={this.imageRefs[mockupIndex]}
                                className="mx-2px image-container br d-ib"
                                style={{ height: '34px', width: '28px' }}
                                onMouseEnter={() => {
                                  this.setHoverImageIndex(mockupIndex);
                                }}
                                onMouseLeave={() => {
                                  this.setHoverImageIndex(-1);
                                }}
                              >
                                <Image src={mockup.mockupUrl} className="br h-full" />
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                  {hoverImageIndex >= 0 && anchorData && (
                    <div>
                      <Popover
                        anchorData={anchorData}
                        position="left"
                        style="custom"
                        portalId={popoverParentId}
                        onMouseEnter={() => {}}
                        onMouseLeave={() => {}}
                      >
                        <div
                          style={{
                            width: '345px',
                            height: '420px',
                            boxShadow:
                              '0 36px 57px 4px rgba(0,0,0,0.14), 0 13px 69px 12px rgba(0,0,0,0.12), 0 17px 23px -11px rgba(0,0,0,0.2)',
                            zIndex: 10000,
                          }}
                        >
                          <Image src={mockups[hoverImageIndex].mockupUrl} className="br w-full" />
                        </div>
                      </Popover>
                    </div>
                  )}
                </div>
                <div className="w-full pr-p5 pb-25 flex flex-col justify-between pl-p75 lg:pr-0 lg:w-full">
                  <div className="flex-row">
                    <p className="fs-xs color-navy capitalize fw-bold pr-1p5">{productName}</p>
                    <div className="mt-p25">
                      <p className="lg:d-ib">
                        <span className="fs-xs color-navy-700 capitalize">{colorName}</span>
                      </p>
                    </div>
                    <div className="mt-p25">
                      <p className="lg:d-ib lg:flex lg:justify-between items-center">
                        <span className="fs-xs color-navy-700 capitalize">
                          Sizes{`(${totalQuantity})`}: {sizeDisplays.join(', ')}
                        </span>
                        <span
                          className={classnames('fs-xs fw-bold br-full bgc-navy-50 px-p5 items-center', {
                            'd-n': !showEditQty,
                            flex: showEditQty,
                            'color-primary': showQuantities,
                            'color-navy-700': !showQuantities,
                          })}
                          onClick={() => this.setState({ showQuantities: !showQuantities })}
                        >
                          Edit Qty
                          <i className={`mdi fs-icon-1p5 mdi-chevron-${showQuantities ? 'up' : 'down'} fs-icon-1p5`} />
                        </span>
                      </p>
                    </div>
                  </div>
                  {showQuantities && (
                    <div className="lg:mt-1">
                      <ProductQuantitySelector
                        className="md:mt-p5 mb-1"
                        sizeBoxPaddingX="p5"
                        availableSizes={availableSizes}
                        sizesQuantities={sizesQuantities}
                        errors={getCartProductErrors(cartProduct)}
                        warnings={getCartProductWarnings(cartProduct)}
                        compactAlerts={true}
                        product={product}
                        productBrand={productBrand}
                        onQuantityChange={this.changeSize(updateCart)}
                      />
                    </div>
                  )}
                  <div className="flex justify-between mt-p5 lg:mt-p75">
                    <PriceDisplayer
                      label={'Unit Price'}
                      numUnits={1}
                      price={pricePerPiece}
                      priceClassName="fs-md"
                      unitsClassName="d-n fs-xs color-gray-700 fw-bold"
                      labelClassName="fs-xs fw-bold color-navy-700"
                    />
                    <div className="flex items-center">
                      <PriceDisplayer
                        label={'Subtotal'}
                        numUnits={1}
                        price={preCheckoutSubtotal}
                        priceClassName="fs-md"
                        unitsClassName="d-n fs-xs color-gray-500 fw-bold"
                        labelClassName="ta-right fs-xs fw-bold color-navy-700"
                      />
                    </div>
                  </div>
                  {showRemoveItem && (
                    <button className="p-absolute pin-t pin-r" onClick={this.onRemoveItemClick(updateCart)}>
                      {loading ? (
                        <Loading size="small" textClassName="d-n" paddingClassName="p-0" />
                      ) : (
                        <i className="mdi mdi-close color-gray-500 fs-icon-1p5" />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </MutationUpdateCart>
    );
  }
}

export default withRouter(CartProductItem);
