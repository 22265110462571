import * as React from 'react';
import _ from 'lodash';
import { Mutation } from 'react-apollo';
import TextArea from 'inkp-components/dist/Components/TextArea';
import {
  Mutation as MutationDesignResults,
  MutationupdateDesignArgs,
  Design,
} from 'inkp-design-sdk/types.g';
import gql from 'graphql-tag';

class MutationUpdateDesign extends Mutation<MutationDesignResults, MutationupdateDesignArgs> {}

interface Props {
  design: Design,
};

const DEBOUNCE_MS = 500;

const UPDATE_DESIGN_MUTATION = gql`
  mutation UpdateDesignMutation($designId: String!, $update: UpdateDesignInput!) {
    updateDesignFields(designId: $designId, update: $update) {
      id
      userNote
    }
  }
`;

class AddNote extends React.Component<Props> {
  
  updateDesignNoteRequest = (updateDesign: any, designId: string, value: string) => {
    updateDesign({
      variables: {
        designId,
        update: {
          userNote: value ? value : null
        },
      }
    });
  }

  debounceUpdateDesignNote = _.debounce(this.updateDesignNoteRequest, DEBOUNCE_MS);

  onUpdateDesignNote = (updateDesign: any, designId: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    this.debounceUpdateDesignNote(updateDesign, designId, value);
  }

  render () {
    const { design } = this.props;
    return (
      <MutationUpdateDesign mutation={UPDATE_DESIGN_MUTATION} >
        {(updateDesign, { loading: updateDesignLoading, error: updateDesignError, data: updateDesignData }) => (
          <TextArea
            value={_.get(design, 'userNote')}
            placeholder="Add Special Note (e.g. special artwork instruction, background removal, pms colors, etc.)"
            resize={true}
            autoFocus={true}
            onChange={this.onUpdateDesignNote(updateDesign, design.id)}
          />
        )}
      </MutationUpdateDesign>
    )
  }
}

export default AddNote;