import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';

import Button from 'inkp-components/dist/Components/Button';
import {
  AmountsInput,
  Cart,
  Query as QueryOrderResults,
  QueryrequestCompleteQuoteWithoutPrintTypeArgs,
  RequestCompleteQuoteOutput,
  CompleteQuoteItemsWithoutPrintType,
  SHIPPING_SPEED_ENUM,
  QUOTE_COUPON_RESPONSE_LEVEL_ENUM,} from 'inkp-order-sdk/types.g';
import { SHIPPING_SPEED_READ_MAP } from 'inkp-order-sdk/shipping';
import { parseProductItemId } from 'inkp-product-sdk';
import { COUPON_SOURCE_ENUM } from 'inkp-coupon-sdk/types.g';

import PromoCode from '../PromoCode';
import {
  getCouponTotals,
  formatPrice,
  getCartAmounts,
  QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE,
  reduceCoupons,
} from '../../util/Checkout';
import { GetDeliverByDate } from '../../util/Product';

interface Props {
  cart: Cart,
  cartQuotes: RequestCompleteQuoteOutput[],
  showTotal: boolean;
  showInputPromo: boolean;
  showVolumeDiscount: boolean;
  showGuarenteedDelivery: boolean;
  onRemoveCoupon?: (couponCode: string) => void,
  onAddCoupon?: (couponCode: string) => void;
}

class QueryRequestCompleteQuote extends Query<QueryOrderResults, QueryrequestCompleteQuoteWithoutPrintTypeArgs> {}

function getSingleInitQuoteItems (cart: Cart): CompleteQuoteItemsWithoutPrintType[] {
  const singleUnitQuoteItems: CompleteQuoteItemsWithoutPrintType[] = [];
  const seenProductStyleAndDesign: { [key: string]: boolean } = {};
  for (const item of cart.items) {
    const { productId, color } = parseProductItemId(item.product.productItemId);
    const productStyleAndDesign = `${productId}-${color}-${item.designId}`;
    if (seenProductStyleAndDesign[productStyleAndDesign]) {
      continue;
    }
    singleUnitQuoteItems.push({
      product: {
        productItemId: item.product.productItemId,
        quantity: 1,
      },
      designId: item.designId,
    });
  }

  return singleUnitQuoteItems;
}

const CartTotalFields: React.FunctionComponent<Props> = ({
  cart,
  cartQuotes,
  showTotal,
  showInputPromo,
  showVolumeDiscount,
  showGuarenteedDelivery,
  onRemoveCoupon = () => {},
  onAddCoupon = () => {},
}) => {
  const [promoIsOpen, setPromoIsOpen] = React.useState(false);

  const totalAmounts: AmountsInput = getCartAmounts(cartQuotes);
  const totalCoupons = getCouponTotals(cartQuotes);

  const shippingSpeed = cart.shippingDetails && cart.shippingDetails.speed ?
    cart.shippingDetails.speed :
    SHIPPING_SPEED_ENUM.NINE_DAY;

  const stateCode = cart.shippingAddress && cart.shippingAddress.state ?
    cart.shippingAddress.state : null;

  const coupons = reduceCoupons(cartQuotes);
  const userCoupons = coupons.filter((coupon) => {
    return coupon.source !== COUPON_SOURCE_ENUM.INTERNAL;
  })
  const userCoupon = userCoupons.length > 0 && userCoupons[0].level === QUOTE_COUPON_RESPONSE_LEVEL_ENUM.SUCCESS && userCoupons[0];

  const twoWeekDeliverBy = GetDeliverByDate(new Date().toString(), SHIPPING_SPEED_ENUM.NINE_DAY);
  const twoWeekDeliverByDisplay = moment(twoWeekDeliverBy).format('ddd, MMM D');
  
  return (
    <div className="-my-p5">
      <div className="flex justify-between my-p5">
        <span>Subtotal</span>
        <span>{`$${formatPrice(totalAmounts.subtotal - totalAmounts.shipping + totalAmounts.discount)}`}</span>
      </div>
      <div className="flex justify-between my-p5">
        <span>Shipping ({SHIPPING_SPEED_READ_MAP[shippingSpeed]})</span>
        <span>{totalAmounts.shipping > 0 ? `$${formatPrice(totalAmounts.shipping)}` : 'Free'}</span>
      </div>
      <div className="flex justify-between my-p5">
        <span>{stateCode ? `Tax (${stateCode})` : 'Tax'}</span>
        <span>{`$${formatPrice(totalAmounts.tax)}`}</span>
      </div>
      {totalCoupons.filter((totalCoupon) => { return totalCoupon.source === COUPON_SOURCE_ENUM.INTERNAL }).map((totalCoupon) => (
        <div key={`cart-total-fields-${totalCoupon.code}`} className="flex justify-between my-p5">
          <span>{totalCoupon.description || totalCoupon.displayCode || totalCoupon.code}</span>
          <span>-{`$${formatPrice(totalCoupon.discount)}`}</span>
        </div>
      ))}
      {showTotal &&
        <>
          <div className="my-1p5 bc-navy-200 bwb-1"></div>
          <div className="flex justify-between items-center">
            <span className="fw-extra-bold" style={{ fontSize: '18px' }}>Total</span>
            <span className="fw-bold color-primary" style={{ fontSize: '18px' }}>{`$${formatPrice(totalAmounts.total)}`}</span>          
          </div>
        </>
      }
      {(showInputPromo || userCoupon) &&
        <div className="flex justify-between my-p5">
          <div className="flex items-center">
            <span className="pr-p25">
              Promo Code {userCoupon ? (`(${userCoupon.description || userCoupon.displayCode || userCoupon.code})`) : ''}
            </span>
            {showInputPromo &&
              <>
                {userCoupon ? 
                  <Button
                    type="text"
                    color="secondary"
                    size="lg"
                    icon={true}
                    withIcon={true}
                    mdIcon="close-circle"
                    className="fw-bold color-gray-700"
                    noYPadding={true}
                    noXPadding={true}
                    onClick={() => onRemoveCoupon(userCoupon.code)}
                  />       
                :
                  <Button
                    type="text"
                    color="secondary"
                    size="lg"
                    icon={true}
                    withIcon={true}
                    mdIcon={promoIsOpen ? 'minus-circle-outline' : 'plus-circle-outline'}
                    className="fw-bold color-gray-700"
                    noYPadding={true}
                    noXPadding={true}
                    onClick={() => { setPromoIsOpen(!promoIsOpen) }}
                  />          
                }
              </>
            }
          </div>
          {userCoupon && totalCoupons.filter((totalCoupon) => { return totalCoupon.code.toLowerCase() === userCoupon.code}).map((totalCoupon) => (
            <span key={`coupon-discount-${totalCoupon.code}`}>-{`$${formatPrice(totalCoupon.discount)}`}</span>      
          ))}
        </div>
      }
      <PromoCode 
        onApply={onAddCoupon}
        onRemove={onRemoveCoupon}
        coupons={userCoupons}
        cartQuote={cartQuotes}
        showCoupon={promoIsOpen && !userCoupon}
      />
      {showVolumeDiscount && 
        <QueryRequestCompleteQuote
          query={QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE}
          fetchPolicy="no-cache"
          variables={{ items: getSingleInitQuoteItems(cart) }}
        >
          {({ error, loading, data }) => {
          
          let percentageDiscount = 0;
          if (error || !data || !data.requestCompleteQuoteWithoutPrintType) {
            if (Sentry.captureMessage) {
              Sentry.captureMessage(`Failed to get single item quote for cart ${cart.id}: error: ${JSON.stringify(error)}, data: ${JSON.stringify(data)}`);
            }
          } else {
            const singleQuotes = data.requestCompleteQuoteWithoutPrintType;
            const subtotalWithSinglePricing = singleQuotes.reduce((accumulator: number, singleQuote: RequestCompleteQuoteOutput) => {
              const { productId, color } = parseProductItemId(singleQuote.product.productItemId);
              const cartItems = _.filter(cart.items, (cartItem) => {
                const { productId: cartProductId, color: cartProductColor } = parseProductItemId(cartItem.product.productItemId);
                return cartProductId === productId && cartProductColor === color && cartItem.designId === singleQuote.designId;
              });
              const quantity = _.sumBy(cartItems, (cartItem) => { return cartItem.product.quantity });
              return accumulator + quantity * (singleQuote.amounts.blanks + singleQuote.amounts.printing);
            }, 0);
            // TODO: implement coupons for QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE request
            if (subtotalWithSinglePricing !== 0) {
              percentageDiscount = Math.round(100 * (1 - ((totalAmounts.blanks + totalAmounts.printing) / subtotalWithSinglePricing)));
            }
          }
          return (
            <ul className="pl-1">
              <li className="color-green">
                { percentageDiscount > 0 ? 
                  <span className="-ml-p25 fw-bold fs-xs">
                    {percentageDiscount}% Volume Discount
                  </span>
                :
                  <span className="-ml-p25 fw-normal fs-xs color-navy">
                    Order more products to get a volume discount!
                  </span>
                }                  
              </li>
            </ul>
          )}}
        </QueryRequestCompleteQuote>
      }
      {showGuarenteedDelivery &&
        <ul className="pl-1">
          <li className="color-green">
            <div className="-ml-p25">
              <span className="fw-normal color-navy fs-xs">
                {`Guaranteed delivery by `}
              </span>
              <span className="fw-bold color-green fs-xs">
                {`${twoWeekDeliverByDisplay} `}
              </span>
              <span className="fw-normal color-navy fs-xs">
                with Free 9 Day Delivery
              </span>
            </div>
          </li>
        </ul>
      }
    </div>
  )
}

export default CartTotalFields;