import gql from "graphql-tag";

export const QUERY_ACTIVE_PROMOTIONS = gql`
  query ActivePromotions {
    activePromotions {
      id
      couponCode
      startDate
      endDate
      displays {
        text
        action
        type
      }
    }
  }
`;