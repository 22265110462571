import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { adopt } from 'react-adopt';
import gql from 'graphql-tag';
import { Query, Mutation, MutationFn } from 'react-apollo';

export const QUERY_CURRENT_ASSUMED = gql`
  query {
    currentAssumedUser {
      id
    }
  }
`

export const MUTATION_ASSUME = gql`
  mutation($targetUserId: String!) {
    assume(targetUserId: $targetUserId) {
      id
    }
  }
`

export const MUTATION_UNASSUME = gql`
  mutation {
    unassume {
      id
    }
  }
`

export const AssumeComposed = adopt({
  quCurrentAssumed: ({ render }: any) => (
    <Query query={QUERY_CURRENT_ASSUMED}>
      {(gqlQuCurrentAssumed: any) => render(gqlQuCurrentAssumed)}
    </Query>
  ),
  muUnassume: ({ onSignOut, render }: any) => (
    <Mutation
      mutation={MUTATION_UNASSUME}
      onCompleted={onSignOut}
    >
      {(gqlMuUnassume: any, result: any) => render({gqlMuUnassume, result})}
    </Mutation>
  ),
});

interface AssumeHeaderProps {
  assumedAs: string;
  unassume: any;
}

export class AssumeHeader extends React.Component<AssumeHeaderProps> {
  render() {
    return (
      <div className="flex w-full bgc-red p-1">
        <div className="flex justify-center items-center w-2/3 ta-center">
          <span className="color-white fs-lg fw-bold">Assumed as: {this.props.assumedAs}</span>
        </div>
        <div className="w-1/3">
          <button
            className="w-full br bgc-white p-1"
            onClick={this.props.unassume}
          >
            Unassume
          </button>
        </div>
      </div>
    )
  }
}
