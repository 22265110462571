import * as React from 'react';

import LayoutHeader from 'inkp-components/dist/Components/LayoutHeader';
import LayoutFooter from 'inkp-components/dist/Components/LayoutFooter';
import { url, routes, path } from 'inkp-routes/public';
import globalConfig from 'inkp-config';

import config from '../../config';

interface Props { }

interface State {
  error: any;
  errorInfo: any;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Display fallback UI
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <React.Fragment>
          <div className="flex-no-grow flex-no-shrink flex-basis-auto">
            <LayoutHeader
              links={{
                base: routes.app.base,
                products: routes.app.product.base,
                designLab: routes.app.designTool,
                whyInkpop: routes.app.about,
                help: `${routes.app.help}?tab=Help%20%26%20FAQ`,
                signIn: routes.app.account.signIn,
                register: routes.app.account.register,
                retrieveDesign: routes.app.account.retrieveDesign,
                trackOrder: routes.app.account.trackOrder,
                orders: `${routes.app.account.myAccount}?tab=Orders`,
                savedDesigns: `${routes.app.account.myAccount}?tab=Saved Designs`,
                accountDetail: `${routes.app.account.myAccount}?tab=Account Details`,
                checkout: routes.app.checkout.cart,
              }}
              phone={globalConfig.contact.phone}
            />
          </div>
          <div key={this.props.key} className="flex-grow flex-no-shrink flex-basis-auto">
            <div className="w-full lg:w-container lg:m-auto lg:mb-3 py-2 h-full">
              <div className="d-b w-full flex flex-flow-column items-center content-center justify-center ta-center h-full">
                <img
                  className="lg:w-3/5"
                  src="https://inkp-production.32pt.com/public/app/Inkpop+Emotion+Error-01.png"
                  style={{
                    maxWidth: 224,
                  }}
                />
                <h1 className="fs-lg lg:fs-xl lg:w-3/5">Oh no! Something went wrong!</h1>
              </div>
            </div>
          </div>
          <div className="flex-no-grow flex-no-shrink flex-basis-auto">
            <LayoutFooter
              phone={globalConfig.contact.phone}
              links={{
                aboutUs: routes.app.about,
                blog: config.blog,
                sendEmail: '#',
                retrieveDesign: routes.app.account.retrieveDesign,
                faq: `${routes.app.help}?tab=Help%20%26%20FAQ`,
                privacyPolicy: routes.app.privacyPolicy,
                termsService: routes.app.termsOfService,
                returnsExchanges: `${routes.app.help}?tab=Returns%20%26%20Exchanges`,
                fb: globalConfig.contact.facebookPageUrl,
                instagram: globalConfig.contact.instagramPageUrl,
                pinterest: globalConfig.contact.pinterestPageUrl,
              }}
              onTrackOrder={() => { }}
            />
          </div>
        </React.Fragment>
      );
    }
    return React.Children.map(children, (child: JSX.Element) => {
      return child;
    });
  }
}

export default ErrorBoundary;
