import {
  TYPE_PRODUCT_CATEGORIES,
  SLEEVE_PRODUCT_CATEGORIES,
  GENDER_PRODUCT_CATEGORIES,
  FEATURE_PRODUCT_CATEGORIES,
  COLLAR_PRODUCT_CATEGORIES,
} from 'inkp-product-sdk/constants';
import { ProductCategory } from 'inkp-product-sdk';

import { SIZES_BY_BRAND, SizeGuideProduct, SizeUnit } from './constants';

export const imageByProductCategory = (product: { categories: ProductCategory[] }) => {
  let category;
  let gender;
  let isShortSleeve = false;
  let isLongSleeve = false;
  let isVneck = false;
  let hasZip = false;
  let hasRacerback = false;
  for (const _category of product.categories) {
    if (!category && _category.scope === 'type') { category = _category.name; }
    if (!gender && _category.scope === 'gender') { gender = _category.name; }
    if (_category.name === SLEEVE_PRODUCT_CATEGORIES.SHORT_SLEEVE) { isShortSleeve = true; }
    if (_category.name === SLEEVE_PRODUCT_CATEGORIES.LONG_SLEEVE) { isLongSleeve = true; }
    if (_category.name === COLLAR_PRODUCT_CATEGORIES.V_NECK) { isVneck = true; }
    if (_category.name === FEATURE_PRODUCT_CATEGORIES.ZIPPER) { hasZip = true; }
    if (_category.name === FEATURE_PRODUCT_CATEGORIES.RACERBACK) { hasRacerback = true; }
  }
  const isLadies = gender === GENDER_PRODUCT_CATEGORIES.LADIES;
  const imageUrl = (filename: string) => "https://inkp-production.s3-us-west-2.amazonaws.com/public/size-guides/" + filename;
  if (category === TYPE_PRODUCT_CATEGORIES.T_SHIRTS) {
    if (isLongSleeve) {
      return imageUrl("Longsleeve_Tshirt_Flat.png");
    } else if (isLadies && isShortSleeve && isVneck) {
      return imageUrl("WomenShirt_Vneck_Flat.png");
    } else if (!isLadies && isShortSleeve && isVneck ) {
      return imageUrl("TShirt_Vneck_Flat.png");
    }
    return imageUrl("Tshirt_Flat.png");
  } else if (category === TYPE_PRODUCT_CATEGORIES.HOODIES) {
    if (hasZip) {
      return imageUrl("Hoodie_Zip_Flat.png");
    }
    return imageUrl("Hoodie_Flat.png");
  } else if (category === TYPE_PRODUCT_CATEGORIES.JACKETS) {
    if (isLadies) {
      return imageUrl("Jacket_Flat.png");
    }
    return imageUrl("Jacket_Flat_02.png");
  } else if (category === TYPE_PRODUCT_CATEGORIES.SWEATSHIRTS) {
    if (isLadies) {
      return imageUrl("Sweatshirt_Flat_02.png");
    }
    return imageUrl("Sweatshirt_Flat.png");
  } else if (category === TYPE_PRODUCT_CATEGORIES.TANK_TOPS) {
    if (hasRacerback && isLadies) {
      return imageUrl("WomenRacerback_Flat.png");
    } else if (!hasRacerback && isLadies) {
      return imageUrl("WomenTank_Flat.png");
    }
    return imageUrl("Tank_Flat.png");
  }
  return imageUrl("Tshirt_Flat.png");
}

export const cmToInch = (cm: number) => {
  return (cm || 0) / 2.54;
}

export const inchToCm = (inch: number) => {
  return (inch || 0) * 2.54;
}

export const sizeByProduct = (product: SizeGuideProduct, brand: string, size: string, unit: SizeUnit): { width: string, length: string } => {
  const defaultSize = "-";
  const sizeKey = size.toUpperCase();
  const sizesByBrand = SIZES_BY_BRAND[brand];
  const sizesByStyle = sizesByBrand.sizes.find((size) => size.styleId.toLowerCase() === product.styleId.toLowerCase()) || sizesByBrand.sizes[0];
  const sizeDimensions = sizesByStyle[sizeKey] || {};
  const width = unit === 'in' ? sizeDimensions.width : inchToCm(sizeDimensions.width);
  const length = unit === 'in' ? sizeDimensions.length : inchToCm(sizeDimensions.length);
  return {
    width: (Math.round(width) || defaultSize).toString(),
    length: (Math.round(length) || defaultSize).toString(),
  };
}