import * as React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import _ from 'lodash';

import { MINIMUM_DTG_QUANTITY } from 'inkp-order-sdk/order';
import { prettyProductSize } from 'inkp-product-sdk/product';
import { ProductSize, Product } from 'inkp-product-sdk/types.g';
import Alert, { AlertProps } from 'inkp-components/dist/Components/Alert';
import Modal from 'inkp-components/dist/Components/Modal';
import Input from 'inkp-components/dist/Components/Input';

import { CART_PRODUCT_ERRORS, CART_PRODUCT_WARNINGS, STRAIGHTEN_SVG } from '../../util/Checkout';
import { MAX_PRODUCT_INPUT } from '../../util/Product';
import SizeGuide, { hasSizeGuide } from '../SizeGuide';

const minimumQuantityAlert = (compact: boolean): AlertProps => {
  return {
    type: 'warning',
    title: compact ? `Minimum Quantity of ${MINIMUM_DTG_QUANTITY} Required` : 'Minimum Quantity Required',
    content: compact ? null : <span>Your product has an order minimum of {MINIMUM_DTG_QUANTITY}.</span>,
  };
};

const maximumQuantityAlert = (compact: boolean): AlertProps => {
  return {
    type: 'error',
    title: 'Maximum Quantity Exceeded',
    content: compact ? null : <span>Please contact our customer service for large orders.</span>,
  };
};

const requiredQuantityAlert = (compact: boolean): AlertProps => {
  return {
    type: 'error',
    title: 'Enter quantity or remove item to proceed',
    content: compact ? null : <span></span>,
  };
};

interface Props {
  className?: string;
  sizeBoxPaddingX?: string;
  product?: Product;
  productBrand?: string;

  availableSizes: ProductSize[];
  sizesQuantities: { [id: string]: number };
  errors?: { code: CART_PRODUCT_ERRORS; data?: any }[];
  warnings?: CART_PRODUCT_WARNINGS[];
  compactAlerts?: boolean;

  onQuantityChange: (size: string, quantity: number) => void;
  sizeGuideParentElementId?: string;
}

interface State {
  showSizeGuide: boolean;
}

class ProductQuantitySelector extends React.Component<Props, State> {
  state = {
    showSizeGuide: false,
  };

  onSizeGuideClick = () => {
    this.setState({
      showSizeGuide: true,
    });
  };

  onSizeGuideClose = () => {
    this.setState({
      showSizeGuide: false,
    });
  };

  onChange = (size: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = ev.currentTarget.value;
    const newQuantity: number = newValue === '' ? 0 : parseInt(newValue, 10);
    if (isNaN(newQuantity) || newQuantity < 0 || newQuantity > MAX_PRODUCT_INPUT) {
      return;
    }
    this.props.onQuantityChange(size, newQuantity);
  };

  renderAlerts() {
    const { errors, warnings, compactAlerts = false } = this.props;
    const alerts = [];

    // TODO - remove compactAlerts check after quote drawer is deprecated
    if (compactAlerts && _.find(errors, { code: CART_PRODUCT_ERRORS.QUANTITY_REQUIRED })) {
      alerts.push(requiredQuantityAlert(compactAlerts));
    }
    if (_.find(errors, { code: CART_PRODUCT_ERRORS.MAXIMUM_QUANTITY_EXCEEDED })) {
      alerts.push(maximumQuantityAlert(compactAlerts));
    }
    if (_.includes(warnings, CART_PRODUCT_WARNINGS.MINIMUM_QUANTITY_REQUIRED)) {
      alerts.push(minimumQuantityAlert(compactAlerts));
    }

    if (!alerts.length) return null;

    return <Alert alerts={alerts} className="shadow-none mb-1" compact={compactAlerts} />;
  }

  render() {
    const {
      errors,
      availableSizes,
      sizesQuantities,
      product,
      productBrand,
      compactAlerts = false,
      sizeGuideParentElementId = 'root',
    } = this.props;
    const { showSizeGuide } = this.state;
    const element = typeof document === 'object' && document.getElementById(sizeGuideParentElementId);
    return (
      <div className={this.props.className}>
        <div className="mt-1 mb-p5 fs-sm flex justify-between color-navy-800 fw-bold">
          <div className="fs-md">Sizes</div>
          {productBrand && hasSizeGuide(productBrand) && (
            <div className="d-ib cursor-pointer flex items-center" onClick={this.onSizeGuideClick}>
              <img src={STRAIGHTEN_SVG} className="color-navy-800 fs-icon-p5" />
              <span className="px-p25 fs-xs fw-bold color-navy-800">Size Guide</span>
            </div>
          )}
        </div>
        {this.renderAlerts()}
        <div
          className={classnames('flex flex-wrap', {
            [`-mx-${this.props.sizeBoxPaddingX}`]: this.props.sizeBoxPaddingX,
          })}
        >
          {availableSizes.map((availableSize: ProductSize, availableSizeIndex: number) => {
            const value: string = sizesQuantities[availableSize.name] ? `${sizesQuantities[availableSize.name]}` : '';
            const inStock = availableSize.inStock;
            const showErrorState = compactAlerts && errors && errors.length > 0;
            return (
              <div
                key={`sizesgroup-${availableSizeIndex}`}
                className={classnames('w-1/5 md:w-1/4 lg:w-1/5 mt-p25 mb-p5 size d-ib ta-center', {
                  'color-gray-500': !inStock,
                  [`px-${this.props.sizeBoxPaddingX}`]: this.props.sizeBoxPaddingX,
                  'color-red': showErrorState,
                })}
              >
                <label
                  className={classnames('d-b mb-p25 fs-xs uppercase fw-bold', { 'color-red': showErrorState })}
                  htmlFor=""
                >
                  {prettyProductSize(availableSize.name)}
                </label>
                <Input
                  className="d-b h-2p5 m-auto br ta-center color-navy w-full bgc-white"
                  type="boxed"
                  inputType="number"
                  inputClassName="ta-center"
                  error={showErrorState}
                  borderRadiusClass="br-p5"
                  label=""
                  value={value}
                  disabled={!inStock}
                  onChange={this.onChange(availableSize.name)}
                />
                {!inStock && (
                  <label className="label d-b mb-p25 fs-xs-n fw-bold px-p25 py-p25" htmlFor="">
                    Out of stock
                  </label>
                )}
              </div>
            );
          })}
        </div>
        {element &&
          showSizeGuide &&
          product &&
          productBrand &&
          ReactDOM.createPortal(
            <Modal
              onCloseClick={this.onSizeGuideClose}
              onOverlayClick={this.onSizeGuideClose}
              title="Size guide"
              headerPadding="px-1p25 py-p75"
              headerTextAlignment=" center"
            >
              <SizeGuide
                className="fs-xs md:fs-sm m-1"
                product={product}
                brand={productBrand}
                sizes={availableSizes.map((size) => {
                  return size.name;
                })}
              ></SizeGuide>
            </Modal>,
            element
          )}
      </div>
    );
  }
}

export default ProductQuantitySelector;
