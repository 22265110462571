import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Query } from 'react-apollo';
import { DataProxy } from 'apollo-cache';

import { Cart, RequestCompleteQuoteOutput, AmountsInput } from 'inkp-order-sdk/types.g';
import { ProductColor, Product, ProductSize } from 'inkp-product-sdk/types.g';
import { Design } from 'inkp-design-sdk/types.g';
import { PRODUCT_CATEGORY_SCOPES } from 'inkp-product-sdk/constants';
import { Query as OrderQueryResults, QueryrequestCartQuoteArgs } from 'inkp-order-sdk/types.g';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';
import {
  QUERY_REQUEST_CART_QUOTE,
  joinCartProductsWithQuotes,
  getCartProductErrors,
  getCartProductWarnings,
  getCartAmounts,
  formatPrice,
  MutationUpdateCart,
  MUTATION_UPDATE_CART,
  MutationFnUpdateCart,
  updateCurrentCart,
} from '../../util/Checkout';
import { CartItemsToCartProducts } from '../../util/Product';
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';
import { CartProductWithQuotes } from '../../interfaces/CartCheckout';
import { CartProduct } from '../../interfaces/CartProduct';
import ProductQuantitySelector from '../ProductQuantitySelector';
import AddNote from '../AddNote';
import CartTotalFields from '../CartTotalFields';
import { ProgressBar } from '../ProgressBar';
import CartProductDisplay from '../CartProductDisplay';

class QueryRequestCartQuote extends Query<OrderQueryResults, QueryrequestCartQuoteArgs> {}

export enum Menu {
  ADD_NOTE = 'ADD_NOTE',
  EDIT_QTY = 'EDIT_QTY',
}

export interface CartProductState {
  cartProduct: CartProduct;
  menu: Menu | null;
  ref: React.RefObject<HTMLDivElement>;
  skipErrorMessage: boolean;
  onAddToCart?: () => void;
}

interface Props {
  cart: Cart;
  cartProductStates: CartProductState[];
  products: Product[];
  sizeGuideParentElementId: string;
  onAddCoupon: (
    cartQuotes: RequestCompleteQuoteOutput[],
    updateCart: MutationFnUpdateCart
  ) => (couponCode: string) => void;
  onAddNewProduct: (
    cartProductState: CartProductState
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChangeQuantity: (index: number, updateCart: MutationFnUpdateCart) => (size: string, qty: number) => void;
  onCheckoutClick: () => () => void;
  onEditDesignClick: (cartProductsWithQuotes: CartProductWithQuotes) => () => void;
  onRemoveCoupon: (
    cartQuotes: RequestCompleteQuoteOutput[],
    updateCart: MutationFnUpdateCart
  ) => (couponCode: string) => void;
  onRemoveItemClick: (index: number) => () => void;
  onToggleAddNote: (index: number) => () => void;
  onToggleEditQuantity: (index: number) => () => void;
  progressBar?: { seconds: number; completed: boolean; onComplete: () => void };
}

export default class CartContent extends React.Component<Props> {
  renderEditQuantity(index: number, updateCart: MutationFnUpdateCart) {
    const { cartProductStates, onChangeQuantity, sizeGuideParentElementId } = this.props;
    const cartProduct = cartProductStates[index].cartProduct;
    const currentColor: ProductColor = cartProduct.product.colors.find((color: ProductColor) => {
      return color.name.toLowerCase() === cartProduct.colorId.toLowerCase();
    }) as ProductColor;

    const availableSizes = currentColor.sizesObjects as ProductSize[];
    const productBrand: string = (
      cartProduct.product.categories.find((category) => {
        return category.scope === PRODUCT_CATEGORY_SCOPES.BRAND;
      }) || { name: '' }
    ).name;
    return (
      <ProductQuantitySelector
        className="-mt-p5 mb-1"
        sizeBoxPaddingX="p5"
        product={cartProduct.product}
        productBrand={productBrand}
        availableSizes={availableSizes}
        sizesQuantities={cartProductStates[index].cartProduct.sizesQuantities}
        errors={cartProductStates[index].skipErrorMessage ? [] : getCartProductErrors(cartProduct)}
        warnings={getCartProductWarnings(cartProduct)}
        onQuantityChange={onChangeQuantity(index, updateCart)}
        compactAlerts={true}
        sizeGuideParentElementId={sizeGuideParentElementId}
      />
    );
  }

  renderCart(cartProductsWithQuotes: CartProductWithQuotes[], updateCart: MutationFnUpdateCart) {
    const {
      cart,
      cartProductStates,
      onEditDesignClick,
      onToggleAddNote,
      onToggleEditQuantity,
      onRemoveItemClick,
      onAddNewProduct,
    } = this.props;

    return (
      <div className="-my-1p5">
        {_.map(cartProductStates, (cartProductState, index) => {
          const cartProduct = cartProductState.cartProduct;
          const cartProductWithQuote = cartProductsWithQuotes.find((cartProductWithQuote) => {
            return cartProductWithQuote.id === cartProduct.id;
          });
          const editQtyActive = cartProductState.menu === Menu.EDIT_QTY;
          const addNoteActive = cartProductState.menu === Menu.ADD_NOTE;
          const cartItem = cart.items.find((cartItem) => {
            return cartItem.designId === cartProduct.designId;
          });
          const design = cartItem && cartItem.design;
          return (
            <div
              key={`cart-product-${cartProduct.id}`}
              ref={cartProductState.ref}
              className="my-1 md:my-1p5 lg:flex-no-shrink lg:flex-no-grow lg:flex-basis-auto lg:w-auto"
            >
              <CartProductDisplay
                cartId={this.props.cart.id}
                cartItems={this.props.cart.items}
                cartProduct={cartProduct}
                cartProductWithQuotes={cartProductWithQuote}
                error={undefined}
                showEditQty={false}
                popoverParentId="cart-drawer"
                onRemoveItemClick={onRemoveItemClick(index)}
                showRemoveItem={true}
              />
              <div className="h-2 flex justify-between items-center mt-1 mb-p5 md:mt-1p5 md:mb-p75 fw-bold fs-sm color-navy-700">
                <div className="-ml-2px flex items-center">
                  {cartProductWithQuote && (
                    <>
                      <div
                        className="flex items-center cursor-pointer mr-p5 md:mr-1p5"
                        onClick={onEditDesignClick(cartProductWithQuote)}
                      >
                        <i className="d-ib mdi mdi-chevron-left color-navy-700 fs-lg align-middle"></i>
                        <span>Edit Design</span>
                      </div>
                      <div
                        onClick={onToggleAddNote(index)}
                        className={classnames('cursor-pointer py-p25 px-p5', {
                          'color-primary bgc-gray-50 br-1p5': addNoteActive,
                        })}
                      >
                        <span>Add Note</span>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={classnames('flex items-center cursor-pointer py-p25 px-p5 -mr-p5', {
                    'color-primary bgc-gray-50 br-1p5': editQtyActive,
                  })}
                  onClick={onToggleEditQuantity(index)}
                >
                  <span>
                    Edit Qty -{' '}
                    {cartProductWithQuote
                      ? _.sumBy(cartProductWithQuote.quotes, (quote) => {
                          return quote.product.quantity;
                        })
                      : 0}
                  </span>
                  <i
                    className={classnames(
                      'pl-p25 d-ib mdi md-icon',
                      editQtyActive ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    )}
                  />
                </div>
              </div>

              {editQtyActive && this.renderEditQuantity(index, updateCart)}

              {addNoteActive && design && <AddNote design={design as Design} />}

              {/* TODO: Future task */}
              <div className="flex -mx-3 mt-1p5 items-center bwt-1 bwb-1 bc-gray-200 color-gray-700 ta-center fw-bold fs-sm">
                {/* <div className="flex justify-center items-center cursor-pointer w-1/2 bwr-1 bc-gray-200 py-p5">
                  <i className="pr-p25 fs-icon-1 mdi mdi-plus-circle-outline" />
                  <span>Add New Color</span>
                </div> */}

                <div
                  className="flex justify-center items-center cursor-pointer w-full py-p5"
                  onClick={onAddNewProduct(cartProductState)}
                >
                  <i className="pr-p25 fs-icon-1 mdi mdi-plus-circle-outline" />
                  <span>Add New Product</span>
                </div>
              </div>
              {/* <div className="bwb-1 bc-gray-200 mt-1 -mx-1 md:mt-1p5 md:-mx-3" /> */}
            </div>
          );
        })}
      </div>
    );
  }

  renderSummary(cartQuotes: RequestCompleteQuoteOutput[], updateCart: any) {
    const { cart, onAddCoupon, onRemoveCoupon } = this.props;
    return (
      <div className="py-1 md:py-1p5 bgc-gray-50">
        <div className="px-1 md:px-3">
          <div className="fs-lg fw-extra-bold color-navy pb-p75">Order Summary</div>
          <CartTotalFields
            cart={cart}
            cartQuotes={cartQuotes}
            showTotal={false}
            showGuarenteedDelivery={true}
            showInputPromo={true}
            showVolumeDiscount={true}
            onAddCoupon={onAddCoupon(cartQuotes, updateCart)}
            onRemoveCoupon={onRemoveCoupon(cartQuotes, updateCart)}
          />
        </div>
      </div>
    );
  }

  renderCheckoutCTA(cartQuotes: RequestCompleteQuoteOutput[], disabled: boolean, loading: boolean) {
    const { onCheckoutClick } = this.props;
    const totalAmounts: AmountsInput = getCartAmounts(cartQuotes);
    return (
      <div className="md:mb-0 flex flex-col md:flex-row items-center py-1 px-1 md:px-1p5 fs-md color-navy justify-between brt bwt-1 bc-gray-300 bgc-white">
        <div className="price w-full md:w-1/3">
          <div className="flex w-full justify-between pb-p5 md:flex-col md:pb-0">
            <div className="fw-extra-bold">All-Inclusive Price</div>
            {totalAmounts.total > 0 ? (
              <div className="fw-extra-bold color-primary fs-lg">{`$${formatPrice(totalAmounts.total)}`}</div>
            ) : (
              <div className="fw-extra-bold color-gray-500 fs-lg">$--</div>
            )}
          </div>
        </div>
        <div className="addToCartButton w-full md:w-2/3 lg:pl-1">
          <Button
            type="default"
            color="primary"
            className="py-1 px-1 fs-md p-relative"
            block={true}
            onClick={onCheckoutClick()}
            disabled={disabled}
          >
            {loading ? (
              <Loading size="medium" textClassName="d-n" paddingClassName="pt-2px pb-1px" />
            ) : (
              <div className="w-full flex justify-between py-p25">
                <span />
                <div style={{ fontSize: '18px' }}>Checkout</div>
                <i className="fs-icon-1p5 mdi mdi-arrow-right"></i>
              </div>
            )}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { cart, products, progressBar } = this.props;
    return (
      <QueryRequestCartQuote
        query={QUERY_REQUEST_CART_QUOTE}
        variables={{ cartId: cart.id }}
        fetchPolicy="network-only"
      >
        {({ error: cartQuoteError, loading: cartQuoteLoading, data: cartQuoteData }) => {
          const cartQuotes = cartQuoteData && cartQuoteData.requestCartQuote;
          if (cartQuoteLoading) {
            return <Loading />;
          }

          if (cartQuoteError || !cartQuotes) {
            return (
              <div className="py-1 px-1 md:px-3 md:py-1p5">
                Oops! Something went wrong. We are very sorry. Please try again later or contact us.
              </div>
            );
          }

          const cartProducts: CartProduct[] = CartItemsToCartProducts(cart.items, products);
          const cartProductsWithQuotes: CartProductWithQuotes[] = joinCartProductsWithQuotes(cartProducts, cartQuotes);
          const itemsQuantity = _.sumBy(cart.items, (cartItem) => {
            return cartItem.product.quantity;
          });

          return (
            <MutationUpdateCart
              mutation={MUTATION_UPDATE_CART}
              update={(cache: DataProxy, { data: { updateCart } }: any) => {
                updateCurrentCart(cache, updateCart);
                GTM.push(GTMTypes.CART);
              }}
              refetchQueries={[
                {
                  query: QUERY_REQUEST_CART_QUOTE,
                  variables: { cartId: cart.id },
                },
              ]}
              awaitRefetchQueries={true}
            >
              {(updateCart, { loading: updateCartLoading, error: updateCartError }) => (
                <div className="w-full h-full flex flex-col">
                  {progressBar && (
                    <div className="flex-no-grow flex-no-shrink flex-basis-auto">
                      <ProgressBar
                        seconds={progressBar.seconds}
                        completed={progressBar.completed}
                        onComplete={progressBar.onComplete}
                      />
                    </div>
                  )}
                  <div className="flex-grow flex-no-shrink flex-basis-auto">
                    <div className="w-full h-full flex flex-col justify-between p-relative">
                      <style jsx={true}>{`
                        .cart-cta {
                          width: 100%;
                        }

                        .cart-cta-clearblock {
                          height: 130px;
                        }

                        @media (min-width: 768px) {
                          .cart-cta {
                            width: 556px;
                          }

                          .cart-cta-clearblock {
                            height: 92px;
                          }
                        }
                      `}</style>
                      <div className="px-1 md:px-3 py-1p5">{this.renderCart(cartProductsWithQuotes, updateCart)}</div>
                      <div className="w-full mt-1p5">
                        {this.renderSummary(cartQuotes, updateCart)}
                        <div className="cart-cta-clearblock"></div>
                        <div className="p-fixed pin-b cart-cta">
                          {this.renderCheckoutCTA(
                            cartQuotes,
                            !!(updateCartLoading || updateCartError) || itemsQuantity === 0,
                            updateCartLoading
                          )}
                        </div>
                      </div>
                      {progressBar && <div className="p-absolute pin bgc-white opacity-75" />}
                    </div>
                  </div>
                </div>
              )}
            </MutationUpdateCart>
          );
        }}
      </QueryRequestCartQuote>
    );
  }
}
