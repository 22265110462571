import * as React from 'react';
import classnames from 'classnames';
import {
  CartCoupon,
  RequestCompleteQuoteOutput,
  QuoteCouponResponse,
  QUOTE_COUPON_RESPONSE_LEVEL_ENUM,
} from 'inkp-order-sdk/types.g';

import Input from 'inkp-components/dist/Components/Input';

interface Props {
  onApply: (code: string) => void;
  onRemove: (code: string) => void;
  coupons: CartCoupon[];
  cartQuote: RequestCompleteQuoteOutput[];
  showCoupon: boolean;
}

interface State {
  code: string;
}

class PromoCode extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { coupons } = props;
    const firstCoupon: CartCoupon | undefined = coupons[0];
    const code: string = firstCoupon ? firstCoupon.code : '';
    this.state = {
      code,
    };
  }

  onPromoCodeChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const codeInput = event.target.value || '';
    const capsCode = codeInput.toUpperCase().trim();
    return this.setState({
      code: capsCode,
    });
  };

  isCouponValid = (coupon: CartCoupon, cartQuote: RequestCompleteQuoteOutput[]): boolean | undefined => {
    if (!coupon) {
      return false;
    }
    const { code } = coupon;

    const res = cartQuote
      .map((cartItemQuote: RequestCompleteQuoteOutput) => {
        return cartItemQuote.couponResponse.find((couponQuote: QuoteCouponResponse) => {
          return couponQuote.code.toLowerCase() === code.toLowerCase();
        });
      })
      .filter((cartItemQuote: QuoteCouponResponse | undefined) => cartItemQuote);

    if (res.length === 0) {
      // we are returning undefined because, there might be a time, when the Cart is updated in Apollo, but the CartQuote
      // request hasn't returned. At this moment, when we render the validation for the coupon, we might display the message
      // that the coupon is invalid, whilst we might not know that yet because the updated CartQuote info hasn't returned yet.
      // And then when it returns and the coupon is valid, we will render the Coupon is valid message, but in the meanwhile
      // it would look weird that we render first the bad one and then the good one.
      return undefined;
    }

    return (
      res.filter((couponQuote) => {
        return !!couponQuote && couponQuote.level === QUOTE_COUPON_RESPONSE_LEVEL_ENUM.SUCCESS;
      }).length > 0
    );
  };

  onApply = (event: React.FormEvent<HTMLFormElement>) => {
    const { code } = this.state;
    return this.props.onApply(code);
  };

  onRemove = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    const { code } = this.state;
    if (this.props.coupons.length > 0) this.props.onRemove(code);
    this.setState({ code: '' });
  };

  renderFeedbackMessage = (isCouponValid: boolean) => {
    return isCouponValid ? (
      <div className="flex items-center mt-p5 ml-1 fs-xs fw-bold color-green">
        <i className="mdi mdi-check-circle fs-md fs-sm-icon color-green pr-p25" />
        Promo applied to the order
      </div>
    ) : (
      <div className="mt-p5 ml-1 fs-xs fw-bold color-red">
        <i className="mdi mdi-alert fs-md fs-sm-icon color-red pr-p25" />
        Please enter a valid Promo Code
      </div>
    );
  };

  render() {
    const { coupons, cartQuote, showCoupon } = this.props;
    const { code } = this.state;
    const firstCoupon = coupons[0];
    const isCouponValid: boolean | undefined = this.isCouponValid(firstCoupon, cartQuote);
    return (
      <div className="w-full h-full">
        {showCoupon && (
          <div className="mt-1">
            <div className="w-full p-relative">
              <style jsx>{`
                .apply-code {
                  top: 0.75rem;
                  right: 1rem;
                }
              `}</style>
              <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  if (firstCoupon) return;
                  this.onApply(e);
                }}
              >
                {firstCoupon ? (
                  <Input
                    label="Promo Code"
                    focusedColor="blue"
                    autoFocus={true}
                    defaultValue={code.toUpperCase()}
                    disabled={true}
                  />
                ) : (
                  <Input
                    label="Promo Code"
                    focusedColor="blue"
                    autoFocus={true}
                    value={code}
                    onChange={this.onPromoCodeChange()}
                  />
                )}
                <div className="apply-code p-absolute flex flex-row-reverse">
                  {coupons.length === 0 &&
                    <button
                      className={classnames([
                        'fs-xs py-p25 px-p5 fw-extra-bold capitalize bgc-gray-50 br-1p5 ml-p25',
                        {
                          'color-primary': code.length > 0,
                          'color-primary-500': code.length === 0,
                        },
                      ])}
                      disabled={code.length === 0}
                      type="submit"
                    >
                      apply
                    </button>
                  }
                  <button type="button"
                    className={classnames("fw-extra-bold capitalize bgc-gray-50 br-full p-p25", { 'vis-hidden': code.length === 0 })}
                    onClick={this.onRemove()}
                    style={{ fontSize: '0.2rem' }}
                  >
                    <i className="mdi mdi-close-circle color-gray-700 sm-icon"></i>
                  </button>
                </div>
                {firstCoupon && isCouponValid !== undefined ?
                  this.renderFeedbackMessage(isCouponValid)
                  :
                  <div style={{ height: '26px' }}> </div>
                }
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PromoCode;
