import * as React from 'react';
// @ts-ignore
import LoadingBar from 'react-top-loading-bar';
import { DesignCartDesignSide } from '../../states/global/designCart';

const MAX_PERCENTAGE = 99;
// Avg approximation for 1 side design -> save design mutation
const APROX_SAVE_TIME_PER_SIDE = 4;
// 1 second for the transition to 100%
const BUFFER_TIME = 1;

export function getApproximateSecondsForSaveDesign(sides: DesignCartDesignSide[]) {
  const numberOfSides = sides.filter((side) => {
    return side.shapes.length > 0;
  }).length;
  const seconds = APROX_SAVE_TIME_PER_SIDE * numberOfSides;
  return seconds;
}

interface Props {
  seconds: number;
  calculatingText?: string;
  progressText?: string;
  completeText?: string;
  completed: boolean;
  onComplete: () => void;
}

interface State {
  stepPercentage: number;
  waitingTime: number;
  counter: number;
  loadingBarProgress: number;
}

function getStepPercentage(seconds: number): number {
  return Math.ceil(MAX_PERCENTAGE / seconds);
}

function getProgressPercentageForStep(step: number, stepPercentage: number): number {
  let percentage: number = 0;
  if (step <= 0 || step === 0) {
    return percentage;
  }

  const previousStep = step - 1;
  const previousMaxPercentage = previousStep * stepPercentage;
  const currentMaxPercentage = step * stepPercentage;
  const minRange = previousMaxPercentage + 1;
  const maxRange = currentMaxPercentage;
  percentage = Math.floor(Math.random() * (maxRange - minRange + 1)) + minRange;

  return percentage;
}

export class ProgressBar extends React.Component<Props, State> {
  private timeoutId: number;

  constructor(props: Props) {
    super(props);
    const { seconds } = this.props;
    const stepPercentage = getStepPercentage(seconds);
    const counter = 0;
    const loadingBarProgress = 0;

    this.state = {
      counter,
      loadingBarProgress,
      stepPercentage,
      waitingTime: seconds + BUFFER_TIME,
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  getProgressBarMessage = () => {
    const { completed, onComplete } = this.props;
    const { counter, waitingTime } = this.state;
    if (completed) {
      onComplete();
      return 'Done';
    }
    if (counter > 0) {
      const remainingTime: number = waitingTime - counter;
      return `${remainingTime} second${remainingTime > 1 ? 's' : ''} left`;
    }
    return 'Calculating time...';
  };

  renderProgressBar = () => {
    const { completed } = this.props;
    const { counter, loadingBarProgress, stepPercentage, waitingTime } = this.state;

    if (!completed && counter < waitingTime - BUFFER_TIME) {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(() => {
        const increasedCounter = counter + 1;
        const increasedProgress = getProgressPercentageForStep(increasedCounter, stepPercentage);
        return this.setState((state) => {
          return {
            counter: state.counter + 1,
            loadingBarProgress: increasedProgress,
          };
        });
      }, 1000);
    } else if (!completed && counter < waitingTime) {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(() => {
        return this.setState((state) => {
          return {
            counter: state.counter + 1,
            loadingBarProgress: 99,
          };
        });
      }, 1000);
    }

    return (
      <div className="w-full px-3 py-1p5 bgc-blue-50 color-navy-500 p-relative">
        <style jsx>
          {`
            .step {
              font-size: 1.125rem;
            }
          `}
        </style>
        <p className="step w-full capitalize color-navy-700 italic fw-bold mb-1">generating artwork...</p>
        <p className="flex justify-between m-0">
          <span className="time lowercase">{this.getProgressBarMessage()}</span>
          <span className="percentage fw-bold">{loadingBarProgress}%</span>
        </p>
        <div className="w-full p-absolute pin-b pin-r pin-l bgc-blue-200">
          <LoadingBar className="p-absolute-important" height={3} color="#43446E" progress={loadingBarProgress} />
        </div>
      </div>
    );
  };

  render() {
    const { seconds } = this.props;
    return this.renderProgressBar();
  }
}
