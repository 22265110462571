import * as React from 'react';
import {
  Query as CouponQueryResults,
  Mutation as CouponMutationResults,
  Promotion,
  PromotionDisplay,
  PROMOTION_DISPLAY_ACTION,
  PROMOTION_DISPLAY_TYPE
} from 'inkp-coupon-sdk/types.g';
import { QUERY_ACTIVE_PROMOTIONS } from '../../graphql/coupons';
import { MUTATION_UPDATE_CART, updateCurrentCart, QUERY_REQUEST_CART_QUOTE } from '../../util/Checkout';
import { Cart } from 'inkp-order-sdk/types.g';
import { Query, Mutation, MutationFn } from 'react-apollo';
import _ from 'lodash';
import { DataProxy } from 'apollo-cache';
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

class CouponQuery extends Query<CouponQueryResults> {};

const POLL_INTERVAL_MS = 1000 * 60 * 5;

interface Props {
  cart: Cart,
  type: PROMOTION_DISPLAY_TYPE,
  onSuccess: (successMessage: string) => void,
}

const SHARE_PROMO = 'SHAREPROMO';

function renderBannerWithApplyOnClick (cart: Cart, promotion: Promotion, display: PromotionDisplay, onSuccess: any) {
  const coupons = [{ code: promotion.couponCode.toUpperCase() }];
  if (_.find(cart.coupons, (coupon) => coupon.code.toUpperCase() === SHARE_PROMO)) {
    coupons.push({ code: SHARE_PROMO });
  }

  return (
    <Mutation
      mutation={MUTATION_UPDATE_CART}
      update={(cache: DataProxy, { data: { updateCart } }: any) => {
        updateCurrentCart(cache, updateCart);
        GTM.push(GTMTypes.CART);
      }}
      refetchQueries={[{ query: QUERY_REQUEST_CART_QUOTE, variables: { cartId: cart.id } }]}
      onCompleted={() => onSuccess(`${promotion.couponCode} coupon applied successfully!`)}
    >
    {(muUpdateCart: MutationFn) => (
      <div className="bgc-purple ta-center color-white fw-bold py-p5 px-1">
        { display.text }
        { display.action === PROMOTION_DISPLAY_ACTION.APPLY_ON_CLICK &&
          <span className="underline ml-p5 cursor-pointer" onClick={
            () => muUpdateCart({ 
              variables: {
                id: cart.id,
                data: { coupons },
              },
            })
          }>
          Apply Now
          </span>
        }
      </div>
    )}
  </Mutation>
  )
}


export const Promotions: React.FunctionComponent<Props> = (props) => {
  const { cart, type, onSuccess } = props;
  // const [successMessage, setSuccessMessage] = React.useState(false);

  return (
    <CouponQuery query={QUERY_ACTIVE_PROMOTIONS} pollInterval={POLL_INTERVAL_MS}>
      {({ loading: loadingPromos, error: promoErrors, data: promoData }) => {
        let activePromotion: Promotion | undefined;
        let promotionDisplay: PromotionDisplay | undefined;
        let promoApplied = false;
        if (!promoErrors && promoData && promoData.activePromotions && promoData.activePromotions.length > 0) {
          // Future TODO, support multiple active promotions
          activePromotion = promoData.activePromotions[0];
          promotionDisplay = _.find(activePromotion.displays, (display) => {
            return display.type === type
          });
          promoApplied = !!_.find(cart.coupons, (coupon) => { return coupon.code.toLowerCase() === activePromotion!.couponCode.toLowerCase() });
        }

        if (promotionDisplay &&
          promotionDisplay.action === PROMOTION_DISPLAY_ACTION.APPLY_ON_CLICK &&
          activePromotion &&
          !promoApplied
        ) {
          return renderBannerWithApplyOnClick(cart, activePromotion, promotionDisplay, onSuccess);
        }

        return null;
      }}
    </CouponQuery>
  )
}