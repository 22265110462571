import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import _ from 'lodash';
import * as Qs from 'qs';

import config from '../config';
import globalConfig from 'inkp-config';
import LayoutHeader from 'inkp-components/dist/Components/LayoutHeader';
import LayoutFooter from 'inkp-components/dist/Components/LayoutFooter';
import Loading from 'inkp-components/dist/Components/Loading';
import Modal from 'inkp-components/dist/Components/Modal';
import Notif from 'inkp-components/dist/Components/Notif';
import { parseProductItemId } from 'inkp-product-sdk';
import { url, routes, path } from 'inkp-routes/public';
import { Query as OrderQueryResults, Cart } from 'inkp-order-sdk/types.g';
import { PROMOTION_DISPLAY_TYPE } from 'inkp-coupon-sdk/types.g';

import { formatToPhone, isUserRegistered, hasAb } from 'inkp-user-sdk/user';
import { Query as UserQueryResults, USER_ROLE } from 'inkp-user-sdk/types.g';
import { Query as QueryProductResults } from 'inkp-product-sdk/types.g';

import ErrorBoundary from '../components/ErrorBoundary';
import CartDrawer from '../components/CartDrawer';
import { Promotions } from '../components/Promotions';
import { AssumeComposed, AssumeHeader } from '../helpers/assume';
import { QUERY_CART_BY_USER, calculateCartProductQuantity } from '../util/Checkout';
import { QueryCurrentUser, QUERY_CURRENT_USER, signOutUser, MUTATION_SIGN_OUT } from '../graphql/users';
import { QUERY_PRODUCTS_BY_ID, QueryProductsById } from '../graphql/products';
import { Subscribe } from 'infra-frontend/helpers/apollo';
import DesignCart from '../states/global/designCart';
import { CartProductState } from '../components/CartDrawer/cartContents';

interface Props extends RouteComponentProps {
  key: string;
  children: React.ReactNode;
  showProductsMenu?: boolean;
  showWhyInkpopMenu?: boolean;
  showHelpMenu?: boolean;
  showSpecialistPhoneMenu?: boolean;
  showStartDesigningButton?: boolean;
  startDesigningClassName?: string;
}

interface State {
  cartDrawerOpen: boolean;
  successMessage: string;
}

class OrderQuery extends Query<OrderQueryResults> {}
class QueryProducts extends Query<QueryProductResults> {}

export default class MainLayout extends React.Component<Props, State> {
  state = {
    cartDrawerOpen: false,
    successMessage: '',
  };

  signOutUpdate = () => (cache: DataProxy, { data }: { data: UserQueryResults }) => {
    signOutUser(
      cache,
      {
        id: '',
        email: '',
        name: '',
        role: USER_ROLE.ANON,
        organization: '',
        createdAt: new Date(),
        updatedAt: new Date(),
      },
      QUERY_CURRENT_USER
    );
  };

  onTrackOrder = (currentUser?: any) => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (currentUser && isUserRegistered(currentUser)) {
      this.props.history.push(`${routes.app.account.myAccount}?tab=Orders`);
    } else {
      this.props.history.push(routes.app.account.trackOrder);
    }
  };

  completeSignOut = () => () => {
    window.location.href = url('app', routes.app.base);
  };

  render() {
    const {
      history,
      match: { params },
      location: { search },
      showProductsMenu = true,
      showWhyInkpopMenu = true,
      showHelpMenu = true,
      showSpecialistPhoneMenu = true,
      showStartDesigningButton = true,
      startDesigningClassName,
    } = this.props;
    const { cartDrawerOpen, successMessage } = this.state;

    const { id } = params as any;
    const { color } = Qs.parse(search, { ignoreQueryPrefix: true });
    const designLab =
      this.props.match.path === routes.app.product.detail && id
        ? path(routes.app.designTool, { product: id, color })
        : routes.app.designTool;
    return (
      <Subscribe to={['designCart']} namespace="MainLayout">
        {(designCart: DesignCart) => {
          return (
            <div className="flex flex-col h-full">
              <ErrorBoundary>
                <AssumeComposed onSignOut={this.completeSignOut()}>
                  {({ quCurrentAssumed, muUnassume }: { quCurrentAssumed: any; muUnassume: any }) => {
                    return (
                      <QueryCurrentUser query={QUERY_CURRENT_USER}>
                        {({ loading: loadingUser, error: userLoadError, data: userData }: any) => {
                          if (loadingUser || !userData) {
                            return <Loading />;
                          }
                          if (userLoadError) {
                            console.error('User Load Error');
                            throw new Error('User Load Error');
                          }

                          if (!userData.currentUser) {
                            throw new Error("Couldn't fetch data for currentUser");
                          }

                          const { currentUser } = userData;
                          return (
                            <Mutation
                              mutation={MUTATION_SIGN_OUT}
                              onError={(error: any) => {
                                console.log('error on signout', error);
                              }}
                              onCompleted={this.completeSignOut()}
                              update={this.signOutUpdate()}
                            >
                              {(signOut: MutationFn) => (
                                <OrderQuery query={QUERY_CART_BY_USER}>
                                  {({ loading: loadingCart, error: cartLoadError, data: cartData }) => {
                                    let cart: Cart | undefined;
                                    let cartQuantity = 0;
                                    let productIds: string[] = [];
                                    if (!cartLoadError && cartData && cartData.cartByUser) {
                                      cart = cartData.cartByUser;
                                      cartQuantity = calculateCartProductQuantity(cartData.cartByUser);
                                    }
                                    if (cart) {
                                      productIds = _.uniq(
                                        _.compact(
                                          _.map(cart.items, (cartItem) => {
                                            const { productId } = parseProductItemId(cartItem.product.productItemId);
                                            return productId;
                                          })
                                        )
                                      );
                                    }
                                    return (
                                      <React.Fragment>
                                        {successMessage && (
                                          <Modal
                                            width="519px"
                                            position="start"
                                            padding="px-1 pt-1 md:px-0 fw-bold"
                                            overlayColor=""
                                            onOverlayClick={() => this.setState({ successMessage: '' })}
                                          >
                                            <Notif type="success" onClose={() => this.setState({ successMessage: '' })}>
                                              {successMessage}
                                            </Notif>
                                          </Modal>
                                        )}
                                        {cart && (
                                          <Promotions
                                            cart={cart}
                                            type={PROMOTION_DISPLAY_TYPE.BANNER}
                                            onSuccess={(successMessage: string) => this.setState({ successMessage })}
                                          />
                                        )}
                                        {_.get(quCurrentAssumed, 'data.currentAssumedUser') && (
                                          <AssumeHeader
                                            assumedAs={currentUser.email}
                                            unassume={muUnassume.gqlMuUnassume}
                                          />
                                        )}
                                        <div className="flex-no-grow flex-no-shrink flex-basis-auto">
                                          <LayoutHeader
                                            links={{
                                              base: routes.app.base,
                                              products: routes.app.product.base,
                                              designLab: designLab,
                                              whyInkpop: routes.app.about,
                                              help: `${routes.app.help}?tab=Help%20%26%20FAQ`,
                                              signIn: routes.app.account.signIn,
                                              register: routes.app.account.register,
                                              retrieveDesign: routes.app.account.retrieveDesign,
                                              trackOrder: routes.app.account.trackOrder,
                                              orders: `${routes.app.account.myAccount}?tab=Orders`,
                                              savedDesigns: `${routes.app.account.myAccount}?tab=Saved Designs`,
                                              accountDetail: `${routes.app.account.myAccount}?tab=Account Details`,
                                            }}
                                            user={currentUser}
                                            isUserRegistered={isUserRegistered(currentUser)}
                                            onSignOut={signOut}
                                            phone={globalConfig.contact.phone}
                                            phoneDisplay={formatToPhone(globalConfig.contact.phone).replace(' - ', '-')}
                                            cartQuantity={cartQuantity}
                                            onCartClick={() => {
                                              const { pathname } = this.props.location;
                                              if (hasAb(currentUser, 'cart-drawer', 'true')) {
                                                return this.setState({ cartDrawerOpen: true });
                                              }
                                              if (pathname.search(routes.app.checkout.cart) === -1) {
                                                return this.props.history.push(routes.app.checkout.cart);
                                              }
                                            }}
                                            showProductsMenu={showProductsMenu}
                                            showWhyInkpopMenu={showWhyInkpopMenu}
                                            showHelpMenu={showHelpMenu}
                                            showSpecialistPhoneMenu={showSpecialistPhoneMenu}
                                            showStartDesigningButton={showStartDesigningButton}
                                            startDesigningClassName={startDesigningClassName}
                                          />
                                        </div>
                                        <div
                                          key={this.props.key}
                                          className="content-container flex-grow flex-no-shrink flex-basis-auto"
                                        >
                                          {React.Children.map(this.props.children, (child: JSX.Element) => {
                                            return child;
                                          })}
                                          <QueryProductsById
                                            query={QUERY_PRODUCTS_BY_ID}
                                            variables={{ ids: productIds }}
                                          >
                                            {({
                                              error: productsByIdError,
                                              loading: productsByIdLoading,
                                              data: productsByIdData,
                                            }) => {
                                              const products = _.compact(
                                                productsByIdData && productsByIdData.productsById
                                              );
                                              return (
                                                <div>
                                                  {cartDrawerOpen && cart && (
                                                    <CartDrawer
                                                      products={products}
                                                      cart={cart}
                                                      show={cartDrawerOpen}
                                                      onClose={() => this.setState({ cartDrawerOpen: false })}
                                                      onAddNewProduct={(
                                                        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
                                                        cartProductState: CartProductState
                                                      ) => {
                                                        const { cartProduct } = cartProductState;
                                                        designCart.setSourceProductDesignInfo(
                                                          cartProduct.productId,
                                                          cartProduct.designId
                                                        );
                                                        history.push(
                                                          path(routes.app.designTool, {
                                                            product: cartProduct.productId,
                                                            color: cartProduct.colorId,
                                                            productModal: 'true',
                                                            showMockups: 'true',
                                                          })
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </QueryProductsById>
                                        </div>
                                        <div className="flex-no-grow flex-no-shrink flex-basis-auto">
                                          <LayoutFooter
                                            phone={globalConfig.contact.phone}
                                            links={{
                                              aboutUs: routes.app.about,
                                              blog: config.blog,
                                              sendEmail: '#',
                                              retrieveDesign: routes.app.account.retrieveDesign,
                                              faq: `${routes.app.help}?tab=Help%20%26%20FAQ`,
                                              privacyPolicy: routes.app.privacyPolicy,
                                              termsService: routes.app.termsOfService,
                                              returnsExchanges: `${routes.app.help}?tab=Returns%20%26%20Exchanges`,
                                              fb: globalConfig.contact.facebookPageUrl,
                                              instagram: globalConfig.contact.instagramPageUrl,
                                              pinterest: globalConfig.contact.pinterestPageUrl,
                                            }}
                                            onTrackOrder={this.onTrackOrder(currentUser)}
                                          />
                                        </div>
                                      </React.Fragment>
                                    );
                                  }}
                                </OrderQuery>
                              )}
                            </Mutation>
                          );
                        }}
                      </QueryCurrentUser>
                    );
                  }}
                </AssumeComposed>
              </ErrorBoundary>
            </div>
          );
        }}
      </Subscribe>
    );
  }
}
