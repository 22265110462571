import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DataProxy } from 'apollo-cache';

import { path, routes } from 'inkp-routes/public';
import { Cart, RequestCompleteQuoteOutput, CartItemInput } from 'inkp-order-sdk/types.g';
import { findProductColor } from 'inkp-product-sdk/product';
import { Product } from 'inkp-product-sdk/types.g';
import { COUPON_SOURCE_ENUM } from 'inkp-coupon-sdk/types.g';
import { Design } from 'inkp-design-sdk/types.g';
import RightSidebar from 'inkp-components/dist/Components/RightSidebar';
import MobileSlideModal from 'inkp-components/dist/Components/MobileSlideModal';
import Button from 'inkp-components/dist/Components/Button';
import { Mutation as OrderMutationResults } from 'inkp-order-sdk/types.g';
import {
  CART_PRODUCT_ERRORS,
  MUTATION_UPDATE_CART,
  MutationFnUpdateCart,
  MutationUpdateCart,
  QUERY_REQUEST_CART_QUOTE,
  createNewCartItems,
  getCartProductErrors,
  getCartProductWarnings,
  getCouponTotals,
  updateCurrentCart,
} from '../../util/Checkout';
import { CartItemsToCartProducts, CartProductsToCartItems } from '../../util/Product';
import { CartProduct } from '../../interfaces/CartProduct';
import CartContents, { CartProductState, Menu } from './cartContents';
import DesignCart from '../../states/global/designCart';
import { CartProductWithQuotes } from '../../interfaces/CartCheckout';

import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

const UPDATE_CART_DEBOUNCE_DELAY = 1000;

interface SizeQuantity {
  name: string;
  qty: number;
}

interface Props extends RouteComponentProps {
  cart: Cart;
  onClose: () => void;
  products: Product[];
  show: boolean;
  closeOnZeroItems?: boolean;
  onAddNewProduct: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cartProductState: CartProductState) => void;
  onEditDesignClick?: () => void;
  onOverlayClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  progressBar?: { seconds: number; completed: boolean; onComplete: () => void };
  selected?: {
    design: Design;
    productId: string;
    color: string;
    onAddToCart: () => void;
    sizeQuantities: SizeQuantity[];
  };
}

interface State {
  renderCount: number;
  cartProductStates: CartProductState[];
  shouldCartUpdate: boolean;
}

const EMPTY_CART_SVG = 'https://inkp-production.32pt.com/public/assets/emptyCart.svg';

const getAddedCartProductState = (
  products: Product[],
  cartProductStates: CartProductState[],
  productId: string,
  colorName: string,
  design: Design,
  sizeQuantities: SizeQuantity[]
): CartProductState | null => {
  const existingCartProductState = cartProductStates.find((cartProductState) => {
    return (
      cartProductState.cartProduct.colorId.toLowerCase() === colorName.toLowerCase() &&
      cartProductState.cartProduct.designId === design.id &&
      cartProductState.cartProduct.productId === productId
    );
  });
  if (existingCartProductState) {
    return null;
  }
  const product = products.find((_product) => {
    return _product.id === productId;
  })!;
  const colorObject = findProductColor(product, colorName)!;

  const initializedSizesQuantities =
    sizeQuantities && sizeQuantities.length > 0
      ? sizeQuantities.slice()
      : [colorObject.sizes[0]].map((size) => {
          return { name: size, qty: 1 };
        });

  const newCartItems = createNewCartItems({
    product: product,
    colorName: colorName,
    design: design,
    sizesQuantities: initializedSizesQuantities,
  });

  const newCartProduct: CartProduct = {
    ...CartItemsToCartProducts(newCartItems, products)[0],
  };
  if (!sizeQuantities || !sizeQuantities.length) {
    Object.keys(newCartProduct.sizesQuantities).map((sizeName) => {
      newCartProduct.sizesQuantities[sizeName] = 0;
    });
  }

  return {
    cartProduct: newCartProduct,
    menu: sizeQuantities && sizeQuantities.length > 0 ? null : Menu.EDIT_QTY,
    skipErrorMessage: true,
    ref: React.createRef<HTMLDivElement>(),
  };
};

class CartDrawer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { cart, products, selected } = props;
    const cartProducts: CartProduct[] = CartItemsToCartProducts(cart.items, products);
    let shouldCartUpdate = false;
    let cartProductStates: CartProductState[] = cartProducts.map((cartProduct) => {
      return {
        cartProduct: _.cloneDeep(cartProduct),
        menu: null,
        skipErrorMessage: false,
        ref: React.createRef<HTMLDivElement>(),
      };
    });
    if (selected) {
      const { productId, color, design, onAddToCart, sizeQuantities } = selected;
      const addedCartProductState = getAddedCartProductState(
        products,
        cartProductStates,
        productId,
        color,
        design,
        sizeQuantities
      );
      if (addedCartProductState) {
        addedCartProductState.onAddToCart = onAddToCart;
        cartProductStates = [addedCartProductState].concat(cartProductStates);
      } else {
        cartProductStates = cartProductStates.sort((cartProductStateA) => {
          const matching =
            cartProductStateA.cartProduct.colorId.toLowerCase() === color.toLowerCase() &&
            cartProductStateA.cartProduct.designId === design.id &&
            cartProductStateA.cartProduct.productId === productId;
          return matching ? -1 : 1;
        });
        cartProductStates[0].menu = Menu.EDIT_QTY;
      }
      const hasSizeQuantities = this.hasQuantities(sizeQuantities);
      if (!hasSizeQuantities) {
        cartProductStates[0].menu = Menu.EDIT_QTY;
      }
      shouldCartUpdate = true;
    }

    this.state = {
      renderCount: 0,
      cartProductStates,
      shouldCartUpdate,
    };
  }

  private debouncedRender = _.debounce(() => {
    this.setState((state) => {
      return { renderCount: state.renderCount + 1 };
    });
  }, 200);

  componentDidMount() {
    window.addEventListener('resize', this.debouncedRender);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedRender);
  }

  componentDidUpdate(prevProps: Props) {
    const { cart, products, selected } = this.props;
    if (!cart) return;

    let cartProductStates = _.cloneDeep(this.state.cartProductStates);
    if (selected && !prevProps.selected) {
      const { productId, color, design, onAddToCart, sizeQuantities } = selected;
      const addedCartProductState = getAddedCartProductState(
        products,
        cartProductStates,
        productId,
        color,
        design,
        sizeQuantities
      );
      if (addedCartProductState) {
        addedCartProductState.onAddToCart = onAddToCart;
        cartProductStates = [addedCartProductState].concat(cartProductStates);
      } else {
        cartProductStates = cartProductStates.sort((cartProductStateA) => {
          const matching =
            cartProductStateA.cartProduct.colorId.toLowerCase() === color.toLowerCase() &&
            cartProductStateA.cartProduct.designId === design.id &&
            cartProductStateA.cartProduct.productId === productId;
          return matching ? -1 : 1;
        });
      }
      const hasSizeQuantities = this.hasQuantities(sizeQuantities);
      if (!hasSizeQuantities) {
        cartProductStates[0].menu = Menu.EDIT_QTY;
      }
      return this.setState({
        cartProductStates,
        shouldCartUpdate: true,
      });
    }

    if (!_.isEqual(cart, prevProps.cart)) {
      const cartProducts: CartProduct[] = CartItemsToCartProducts(cart.items, products);
      const updatedCartProductStates: CartProductState[] = cartProducts.map((cartProduct: CartProduct) => {
        const currentCartProductStateForCartProduct = cartProductStates.find((cartProductState) => {
          return cartProduct.id === cartProductState.cartProduct.id;
        });
        if (currentCartProductStateForCartProduct) {
          const {
            cartProduct: { sizesQuantities },
          } = currentCartProductStateForCartProduct;
          const cartProductWithQuantitiesFromCartProductState = {
            ...cartProduct,
            sizesQuantities,
          };
          return {
            ...currentCartProductStateForCartProduct,
            cartProduct: cartProductWithQuantitiesFromCartProductState,
          };
        }
        const { sizesQuantities } = cartProduct;
        const sizeQuantityList: SizeQuantity[] = Object.keys(sizesQuantities).map((sizeQuantityKey) => {
          return {
            name: sizeQuantityKey,
            qty: sizesQuantities[sizeQuantityKey],
          };
        });
        const hasSizesQuantities = this.hasQuantities(sizeQuantityList);
        return {
          cartProduct,
          menu: hasSizesQuantities ? null : Menu.EDIT_QTY,
          skipErrorMessage: true,
          ref: React.createRef<HTMLDivElement>(),
        };
      });

      return this.setState({
        cartProductStates: updatedCartProductStates,
      });
    }
  }

  hasQuantities = (sizeQuantities: SizeQuantity[]): boolean => {
    if (!sizeQuantities || !sizeQuantities.length) return false;
    return _.sumBy(sizeQuantities, (sizeQuantity) => sizeQuantity.qty) > 0;
  };

  isRenderingArtwork = (designCart: DesignCart) => {
    const { state: designCartState } = designCart;
    const currentProduct = designCartState.products[0];
    return currentProduct.designId === null || !designCartState.saved;
  };

  getUpdatedCartItems = () => {
    const { cartProductStates } = this.state;
    const updatedCartProducts: CartProduct[] = [];
    for (let index = 0; index < cartProductStates.length; index++) {
      const cartProductState = cartProductStates[index];
      if (cartProductState.onAddToCart) {
        cartProductState.onAddToCart();
      }
      updatedCartProducts.push({
        ...cartProductState.cartProduct,
        sizesQuantities: cartProductState.cartProduct.sizesQuantities,
      });
    }
    return CartProductsToCartItems(updatedCartProducts);
  };

  updateCart = (updateCart: MutationFnUpdateCart) => {
    const { cart } = this.props;
    const newCartItems: CartItemInput[] = this.getUpdatedCartItems();
    return updateCart({
      variables: {
        id: cart.id,
        data: {
          items: newCartItems,
        },
      },
    });
  };

  private debounceUpdateCart = _.debounce(this.updateCart, UPDATE_CART_DEBOUNCE_DELAY);

  onAddCoupon = (cartQuotes: RequestCompleteQuoteOutput[], updateCart: MutationFnUpdateCart) => (
    couponCode: string
  ) => {
    const { cart } = this.props;
    const currentCoupons = cart.coupons.map((_coupon) => {
      return { code: _coupon.code };
    });
    const internalCoupons = _.filter(getCouponTotals(cartQuotes), (coupon) => {
      return coupon.source === COUPON_SOURCE_ENUM.INTERNAL;
    });

    const isInternal = internalCoupons.find((_coupon) => _coupon.code.toLowerCase() === couponCode.toLowerCase());
    const newCoupons = isInternal ? currentCoupons : currentCoupons.concat([{ code: couponCode }]);
    return updateCart({ variables: { id: cart.id, data: { coupons: newCoupons } } });
  };

  onRemoveCoupon = (cartQuotes: RequestCompleteQuoteOutput[], updateCart: MutationFnUpdateCart) => (
    couponCode: string
  ) => {
    const { cart } = this.props;
    const currentCoupons = cart.coupons.map((_coupon) => {
      return { code: _coupon.code };
    });
    const internalCoupons = _.filter(getCouponTotals(cartQuotes), (coupon) => {
      return coupon.source === COUPON_SOURCE_ENUM.INTERNAL;
    });

    const isInternal = internalCoupons.find((_coupon) => _coupon.code.toLowerCase() === couponCode.toLowerCase());
    const newCoupons = isInternal
      ? currentCoupons
      : currentCoupons.filter((_coupon) => _coupon.code.toLowerCase() !== couponCode.toLowerCase());
    return updateCart({ variables: { id: cart.id, data: { coupons: newCoupons } } });
  };

  onRemoveItemClick = (index: number) => () => {
    const { onClose, closeOnZeroItems } = this.props;
    const cartProductStates = _.cloneDeep(this.state.cartProductStates);
    cartProductStates.splice(index, 1);
    if (cartProductStates.length === 0 && closeOnZeroItems) {
      onClose();
    }
    this.setState({ cartProductStates });
  };

  onChangeQuantity = (index: number, updateCart: MutationFnUpdateCart) => (size: string, qty: number) => {
    const cartProductStates = _.cloneDeep(this.state.cartProductStates);
    const cartProductState = cartProductStates[index];
    cartProductState.cartProduct.sizesQuantities = {
      ...cartProductState.cartProduct.sizesQuantities,
      [size]: qty,
    };
    this.setState({ cartProductStates });
    this.debounceUpdateCart(updateCart);
  };

  onToggleAddNote = (index: number) => () => {
    const cartProductStates = _.cloneDeep(this.state.cartProductStates);
    const cartProductState = cartProductStates[index];
    if (cartProductState.menu === Menu.ADD_NOTE) {
      cartProductState.menu = null;
    } else {
      cartProductState.menu = Menu.ADD_NOTE;
    }
    this.setState({ cartProductStates });
  };

  onToggleEditQuantity = (index: number) => () => {
    const cartProductStates = _.cloneDeep(this.state.cartProductStates);
    const cartProductState = cartProductStates[index];
    if (cartProductState.menu === Menu.EDIT_QTY) {
      cartProductState.menu = null;
    } else {
      cartProductState.menu = Menu.EDIT_QTY;
    }
    this.setState({ cartProductStates });
  };

  onEditDesignClick = (cartProductWithQuote: CartProductWithQuotes) => () => {
    const { history } = this.props;
    if (this.props.onEditDesignClick) {
      this.props.onEditDesignClick();
    }
    return history.push(
      path(routes.app.checkout.editCartDesign, {
        itemIndexes: cartProductWithQuote.cartItemsIndexes.join(','),
      })
    );
  };

  onCheckoutClick = () => () => {
    if (!this.hasErrors()) return this.props.history.push(path(routes.app.checkout.base));
  };

  onAddNewProduct = (cartProductState: CartProductState) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.onAddNewProduct) {
      return this.props.onAddNewProduct(event, cartProductState);
    }
  };

  hasErrors = () => {
    const cartProductStates = _.cloneDeep(this.state.cartProductStates);
    let hasErrors = false;
    for (let index = 0; index < cartProductStates.length; index++) {
      const cartProductState = cartProductStates[index];
      const cartProduct = cartProductState.cartProduct;
      const errors = getCartProductErrors(cartProduct);
      const warnings = getCartProductWarnings(cartProduct);
      if (!hasErrors && (errors.length > 0 || warnings.length > 0)) {
        hasErrors = true;
        const ref = cartProductStates[index].ref;
        const scrollingElement = document.getElementById('cart-drawer');
        if (ref.current && scrollingElement) {
          scrollingElement.scrollTo(0, ref.current.offsetTop - 24);
        }
      }
      if (errors.length > 0 || warnings.length > 0) {
        cartProductStates[index].menu = Menu.EDIT_QTY;
      }
      cartProductStates[index].skipErrorMessage = false;
    }
    if (hasErrors) this.setState({ cartProductStates });
    return hasErrors;
  };

  isCloseDisabled = () => {
    const { cartProductStates } = this.state;
    for (const cartProductState of cartProductStates) {
      const cartErrors = getCartProductErrors(cartProductState.cartProduct);
      if (
        cartErrors.find((cartError) => {
          return cartError.code === CART_PRODUCT_ERRORS.QUANTITY_REQUIRED;
        })
      ) {
        return true;
      }
    }
    return false;
  };

  redirectToProductSelection = () => {
    const { history, onClose } = this.props;
    history.push(
      path(routes.app.product.category, {
        category: 't-shirts',
      })
    );
    setTimeout(() => {
      onClose();
    }, 500);
  };

  closeFnClick = () => {
    const { onClose, onOverlayClick } = this.props;

    if (onOverlayClick) {
      return onOverlayClick;
    } else if (this.isCloseDisabled()) {
      return this.hasErrors;
    }

    return onClose;
  };

  redirectToDesignTools = () => {
    const { history, onClose } = this.props;
    history.push(routes.app.designTool);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  renderHeader() {
    const cartQuantity = this.state.cartProductStates.length;
    const isCloseDisabled = this.isCloseDisabled();
    return (
      <div className="flex w-full items-center">
        <i
          onClick={this.closeFnClick()}
          className={classnames('mdi mdi-close d-b fs-icon-1p5 cursor-pointer', { 'color-gray-700': isCloseDisabled })}
        />
        <div className="flex w-full justify-between items-center pr-0 md:pr-2 pl-1">
          <span className="fs-xl fw-extra-bold">Cart</span>
          <span className="fs-md fw-bold color-navy-700">{cartQuantity} Items</span>
        </div>
      </div>
    );
  }

  renderEmptyCart() {
    return (
      <div className="w-full overflow-none bgc-blue-50 p-relative z-10">
        <div className="p-absolute -z-10 w-full" style={{ height: '471px' }}>
          <svg height="100%" width="100%">
            <circle cx="50%" cy="1010" r="844" stroke="#E8EEF9" strokeWidth="1" fill="#E8EEF9" />
          </svg>
        </div>
        <div className="bgc-transparent m-auto color-navy-700 pt-p75" style={{ height: '471px' }}>
          <div className="w-full pt-5">
            <img className="d-b m-auto" src={EMPTY_CART_SVG}></img>
          </div>
          <div className="pt-3p5 fs-xl fw-extra-bold ta-center">Your cart is empty</div>
          <div className="pt-1 fs-sm xs:fs-md ta-center">Looks like you haven't made your choice yet.</div>
          <div className="fs-sm xs:fs-md ta-center">Start designing or explore products!</div>
          <div className="pt-1p5 flex justify-center">
            <Button onClick={this.redirectToDesignTools}>Start Designing</Button>
          </div>
          <div className="pt-1 flex justify-center">
            <span onClick={this.redirectToProductSelection} className="fw-bold ta-center cursor-pointer">
              or Browse All Products
            </span>
          </div>
        </div>
      </div>
    );
  }

  // * TODO: Remove this after we verified that the new CartDrawer flow is good in mobile. It will means that the
  // * RightSidebarComponent is render properly in mobile as expected.
  // renderMobile() {
  //   const { show, cart, products, progressBar } = this.props;
  //   const { cartProductStates } = this.state;
  //   return (
  //     <div className="d-b md:d-n">
  //       <MobileSlideModal show={show} showHeader={false} height="100vh" slideDirection="left">
  //         <div id="cart-drawer-mobile" className="flex flex-col h-full">
  //           <div className="px-1 my-1px py-p5 flex w-full items-center bc-gray-200 bwb-1">{this.renderHeader()}</div>
  //           {cartProductStates.length > 0 || progressBar ? (
  //             <CartContents
  //               cart={cart}
  //               products={products}
  //               cartProductStates={cartProductStates}
  //               onChangeQuantity={this.onChangeQuantity}
  //               onToggleAddNote={this.onToggleAddNote}
  //               onToggleEditQuantity={this.onToggleEditQuantity}
  //               onEditDesignClick={this.onEditDesignClick}
  //               onCheckoutClick={this.onCheckoutClick}
  //               onRemoveCoupon={this.onRemoveCoupon}
  //               onAddCoupon={this.onAddCoupon}
  //               onRemoveItemClick={this.onRemoveItemClick}
  //               sizeGuideParentElementId={'cart-drawer-mobile'}
  //               progressBar={progressBar}
  //             />
  //           ) : (
  //             this.renderEmptyCart()
  //           )}
  //         </div>
  //       </MobileSlideModal>
  //     </div>
  //   );
  // }

  renderDesktop() {
    const { show, cart, products, progressBar } = this.props;
    const { cartProductStates, shouldCartUpdate } = this.state;

    return (
      <MutationUpdateCart
        mutation={MUTATION_UPDATE_CART}
        update={(cache: DataProxy, { data }: { data: OrderMutationResults }) => {
          updateCurrentCart(cache, data.updateCart);
          GTM.push(GTMTypes.CART);
        }}
        refetchQueries={[
          {
            query: QUERY_REQUEST_CART_QUOTE,
            variables: { cartId: cart.id },
          },
        ]}
        awaitRefetchQueries={true}
      >
        {(updateCart, { loading: mutationUpdateCartLoading }) => {
          if (shouldCartUpdate) {
            this.setState({
              shouldCartUpdate: false,
            });
            this.debounceUpdateCart(updateCart);
          }
          return (
            <RightSidebar
              id="cart-drawer"
              width="556px"
              className="h-cover"
              title={this.renderHeader()}
              show={show}
              onOverlayClick={this.closeFnClick()}
            >
              {cartProductStates.length > 0 || progressBar || mutationUpdateCartLoading ? (
                <CartContents
                  cart={cart}
                  cartProductStates={cartProductStates}
                  products={products}
                  sizeGuideParentElementId={'root'}
                  onAddCoupon={this.onAddCoupon}
                  onAddNewProduct={this.onAddNewProduct}
                  onChangeQuantity={this.onChangeQuantity}
                  onCheckoutClick={this.onCheckoutClick}
                  onEditDesignClick={this.onEditDesignClick}
                  onRemoveCoupon={this.onRemoveCoupon}
                  onRemoveItemClick={this.onRemoveItemClick}
                  onToggleAddNote={this.onToggleAddNote}
                  onToggleEditQuantity={this.onToggleEditQuantity}
                  progressBar={progressBar}
                />
              ) : (
                this.renderEmptyCart()
              )}
            </RightSidebar>
          );
        }}
      </MutationUpdateCart>
    );
  }

  render() {
    return this.renderDesktop();
  }
}

export default withRouter(CartDrawer);
