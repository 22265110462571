import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import ToggleSwitch from 'inkp-components/dist/Components/ToggleSwitch';
import { prettyProductSize } from 'inkp-product-sdk/product';

import { SIZES_BY_BRAND, SizeGuideProduct, SizeUnit } from './constants';
import { imageByProductCategory, sizeByProduct } from './utils';

interface SizeGuideProps {
  brand: string;
  className?: string;
  sizes: string[];
  product: any;
}

interface SizeGuideState {
  sizeUnit: SizeUnit;
  showAllSizes: boolean;
}

export function hasSizeGuide(brand: string): boolean {
  return SIZES_BY_BRAND[brand] !== undefined;
}

const Instructions = (props: {
  product: SizeGuideProduct;
  className?: string;
}) => {
  let sizeGuideImage;
  try {
    sizeGuideImage = imageByProductCategory(props.product);
  } catch (err) {
    console.error(`Cannot find size guide data for ${props.product.id}`);
  }
  return (
    <>
      <div className="fs-mlg fw-bold pt-1p5 bc-navy-200 bwt-1">How to Measure</div>
      <div className="flex">
        <div className="w-1/2">
          <div className="pt-1p5">
            <span className="color-primary fw-bold">Width</span>
            <p>Measured across the chest one inch below arm hole when laid flat.</p>
          </div>
          <div className="pt-p75">
            <span className="color-blue fw-bold">Length</span>
            <p>From the highest point of the shoulder to the bottom seam.</p>
          </div>
        </div>
        <div className="w-1/2 pt-1p5">
          <img className="mx-auto d-b" style={{ maxHeight: '200px' }} src={sizeGuideImage} />
        </div>
      </div>
    </>
  );
}

const SizeTable = (props: {
  product: SizeGuideProduct;
  brand: string;
  sizes: string[];
  showAllSizes: boolean;
  sizeUnit: SizeUnit;
  onClickShowAllSizes: () => void;
}) => {
  const sizes = props.sizes;
  const { showAllSizes = false, product, brand, sizeUnit } = props;
  let isLadies = false;
  let isYouth = false;
  for (const category of product.categories) {
    if (category.scope === 'gender' && category.name === 'ladies') {
      isLadies = true;
    }
    if (category.scope === 'age' && category.name === 'youth'
        || category.scope === 'age' && category.name === 'toddler'
        || category.scope === 'gender' && category.name === 'youth'
        ) {
          isYouth = true;
        }
  }
  let genderLabel = "Unisex";
  if (isYouth) genderLabel = "Youth";
  if (isLadies) genderLabel = "Ladies";

  const TableRow = (props: { items: (string | number)[]; label: string; containerClassName?: string; itemClassName?: string; labelClassName?: string }) => {
    return (
      <div className={classnames(props.containerClassName)}>
        <div className="ta-center flex flex-row items-center justify-around">
          <div className="ta-left" style={{ width: '57px' }}>
            <div className={classnames(props.labelClassName)}>{props.label}</div>
          </div>
            { props.items.map((item) => {
              return (
                <div className="flex-1">
                  <div className={classnames(props.itemClassName)}>{item}</div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }

  let labels = sizes.map((size) => prettyProductSize(size));
  let widths = [];
  let lengths = []
  for (const size of sizes) {
    const sizeData = sizeByProduct(product, brand, size, sizeUnit);
    widths.push(sizeData.width);
    lengths.push(sizeData.length);
  }
  return (
    <div>
      <div className="flex items-center justify-between pt-1p5">
        <div className="fs-mlg fw-extra-bold">{genderLabel} Sizes</div>
        {/* Comment out until we have companion product data available
        <div className="flex items-center fs-xs fw-bold cursor-pointer" onClick={props.onClickShowAllSizes}>
          <span>Show All Gender Sizes</span>
          <i className={classnames("mdi md-icon", { "mdi-chevron-down": showAllSizes, "mdi-chevron-up": !showAllSizes })}/>
        </div>
        */}
      </div>
      <div>
        <TableRow items={labels} label="" labelClassName="pl-p75 fw-bold color-navy-800" itemClassName="fw-bold py-p75 color-navy-800" />
        <TableRow items={widths} label="Width" containerClassName="brl-p5 brr-p5 bgc-gray-50" labelClassName="pl-p75 fw-bold color-navy-800" itemClassName={classnames("py-p75 color-navy-800")} />
        <TableRow items={lengths} label="Length" labelClassName="pl-p75 fw-bold color-navy-800" itemClassName={"py-p75 color-navy-800"} />
      </div>
    </div>
  );
}

const SizeGuide: React.FunctionComponent<SizeGuideProps> = (props: SizeGuideProps) => {
  const [state, setState] = React.useState<SizeGuideState>({
    sizeUnit: 'in',
    showAllSizes: false,
  });

  const toggleInchSwitch = () => {
    setState(
      Object.assign({}, state, {
        sizeUnit: state.sizeUnit === 'in' ? 'cm' : 'in',
      })
    );
  }

  const toggleShowAllSizes = () => {
    setState(
      Object.assign({}, state, {
        showAllSizes: !state.showAllSizes,
      })
    );
  }

  const { brand, className, sizes, product } = props;

  return (
    <div className={classnames("px-p75", className)}>
      <style jsx={true}>{`
        .size-guide-container::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 2px;
        }
        .size-guide-container::-webkit-scrollbar-thumb {
          border-radius: 1px;
          background-color: rgba(0, 0, 0, .3);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
      `}
      </style>
      <div className="overflow-y-auto pb-p25 mb-p5 size-guide-container" style={{ maxHeight: '90vh' }}>
        <div className="flex items-center justify-between pt-p5 pb-1p5 bc-navy-200 bwb-1">
          <div className="fs-mlg fw-extra-bold capitalize">{brand}</div>
          <div className="">
            <ToggleSwitch
              active={state.sizeUnit === 'cm'}
              label="Unit"
              onProps={{ label: 'CM' }}
              offProps={{ label: 'IN' }}
              onClick={toggleInchSwitch}
            />
          </div>
        </div>
        <SizeTable
          product={product}
          brand={brand}
          sizes={sizes}
          sizeUnit={state.sizeUnit}
          showAllSizes={state.showAllSizes}
          onClickShowAllSizes={toggleShowAllSizes}
        />
        <Instructions product={product}/>
      </div>
    </div>
  );
}

export default SizeGuide;