import { ProductCategory } from 'inkp-product-sdk/types.g';

export type SizeUnit = "in" | "cm";

export interface SizeGuideProduct {
  id: string;
  styleId: string;
  categories: ProductCategory[];
}

export interface SizeGuideSizes {
  styleId: string;
  name: string;
  XXSML?: { width: number; length: number };
  XSML?: { width: number; length: number };
  SML?: { width: number; length: number };
  MED?: { width: number; length: number };
  LRG?: { width: number; length: number };
  XLG?: { width: number; length: number };
  XXL?: { width: number; length: number };
  XXXL?: { width: number; length: number };
  XXXXL?: { width: number; length: number };
  [key: string]: any;
}

export interface SizeGuideInfo {
  sizes: SizeGuideSizes[],
}

export const SIZE_ORDER = [ "XXSML", "XSML", "SML", "MED", "LRG", "XLG", "XXL", "XXXL", "XXXXL", "XXXXXL", "XXXXXXL" ];

export const SIZES_BY_BRAND: { [ brand: string ]: SizeGuideInfo } = {
  "anvil":{
    "sizes":[
      {
        "styleId":"980",
        "name":"Men's fashion fit",
        "XSML":{ "width":16, "length":26 },
        "SML":{ "width":18, "length":27.25 },
        "MED":{ "width":20, "length":28.5 },
        "LRG":{ "width":22, "length":29.5 },
        "XLG":{ "width":24, "length":30.5 },
        "XXL":{ "width":26, "length":31.75 },
        "XXXL":{ "width":28, "length":32.75 }
      },
      {
        "styleId":"OR480",
        "name":"Anvil Organic T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 }
      },
      {
        "styleId":"982",
        "name":"Anvil Fashion V-Neck T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 }
      }
    ]
  },
  "alternative":{
    "sizes":[
      {
        "styleId":"AA1072",
        "name":"Alternative Apparel Ladies Basic Crew T-shirt",
        "SML":{ "width":15.5, "length":26 },
        "MED":{ "width":16.5, "length":27 },
        "LRG":{ "width":17.5, "length":28 },
        "XLG":{ "width":18.5, "length":29 }
      },
      {
        "styleId":"AA1070",
        "name":"Alternative Apparel Basic Crew T-shirt - Unisex",
        "XSML":{ "width":26.5, "length":17.5 },
        "SML":{ "width":27.5, "length":19 },
        "MED":{ "width":28.5, "length":20.5 },
        "LRG":{ "width":29.5, "length":22 },
        "XLG":{ "width":30.5, "length":23.5 },
        "XXL":{ "width":31.5, "length":25 },
        "XXXL":{ "width":32.5, "length":26.5 }
      }
    ]
  },
  "american-apparel":{
    "sizes":[
      {
        "styleId":"2001",
        "name":"American Apparel T-Shirt - Unisex",
        "XSML":{ "width":16.5, "length":27.75 },
        "SML":{ "width":18, "length":28.75 },
        "MED":{ "width":20, "length":29.75 },
        "LRG":{ "width":22, "length":30.75 },
        "XLG":{ "width":224, "length":31.75 },
        "XXL":{ "width":26, "length":32.75 },
        "XXXL":{ "width":28, "length":33.75 }
      },
      {
        "styleId":"2456",
        "name":"American Apparel V-Neck T - Unisex",
        "XSML":{ "width":16.5, "length":27.6 },
        "SML":{ "width":18, "length":28.75 },
        "MED":{ "width":20, "length":29.25 },
        "LRG":{ "width":22, "length":30.3 },
        "XLG":{ "width":24, "length":31.3 },
        "XXL":{ "width":26, "length":32.5 }
      },
      {
        "styleId":"2201",
        "name":"American Apparel Youth Jersey T-Shirt",
        "SML":{ "width":15.5, "length":25.75 },
        "MED":{ "width":16.5, "length":26.37 },
        "LRG":{ "width":17.75, "length":27 },
        "XLG":{ "width":18.75, "length":27.6 },
        "XXL":{ "width":20.25, "length":28.75 }
      },
      {
        "styleId":"2102",
        "name":"American Apparel Girly T - Ladies",
        "SML":{ "width":16.63, "length":26 },
        "MED":{ "width":18, "length":26.5 },
        "LRG":{ "width":19, "length":27.125 },
        "XLG":{ "width":20.5, "length":27.75 },
        "XXL":{ "width":21.5, "length":28.875 }
      }
    ]
  },
  "bella":{
    "sizes":[
      {
        "styleId":"6000",
        "name":"Bella Crew Neck Jersey - Ladies",
        "SML":{ "width":17.25, "length":25.75 },
        "MED":{ "width":18, "length":26.25 },
        "LRG":{ "width":19, "length":26.88 },
        "XLG":{ "width":20, "length":27.5 },
        "XXL":{ "width":21, "length":28.13 }
      },
      {
        "styleId":"B6005",
        "name":"Bella Ladies V-Neck",
        "SML":{ "width":17.25, "length":25.75 },
        "MED":{ "width":18, "length":26.25 },
        "LRG":{ "width":19, "length":26.88 },
        "XLG":{ "width":20, "length":27.5 },
        "XXL":{ "width":21, "length":28.13 }
      },
      {
        "styleId":"1001",
        "name":"Bella Ribbed Crew Neck T - Ladies",
        "SML":{ "width":15, "length":25.25 },
        "MED":{ "width":15.75, "length":25.75 },
        "LRG":{ "width":16.75, "length":26.38 },
        "XLG":{ "width":17.75, "length":27 },
        "XXL":{ "width":18.75, "length":27.63 }
      },
      {
        "styleId":"B8413",
        "name":"Bella Triblend Crewneck - Ladies",
        "SML":{ "width":15.75, "length":26 },
        "MED":{ "width":16.5, "length":26.5 },
        "LRG":{ "width":17.5, "length":27.13 },
        "XLG":{ "width":18.5, "length":27.75 },
        "XXL":{ "width":19.5, "length":28.38 }
      },
      {
        "styleId":"B2000",
        "name":"Bella 3/4 Sleeve Baseball T",
        "SML":{ "width":14, "length":23.75 },
        "MED":{ "width":15, "length":24.75 },
        "LRG":{ "width":16, "length":25.75 },
        "XLG":{ "width":17, "length":26.75 },
        "XXL":{ "width":18, "length":27.75 }
      },
      {
        "styleId":"8435",
        "name":"Bella Triblend Deep V-Neck",
        "SML":{ "width":15.63, "length":25.88 },
        "MED":{ "width":16.38, "length":26.38 },
        "LRG":{ "width":17.38, "length":27 },
        "XLG":{ "width":18.38, "length":27.63 },
        "XXL":{ "width":19.38, "length":28.25 }
      },
      {
        "styleId":"B6050",
        "name":"Bella 50/50 Heathered Ringer",
        "SML":{ "width":16.5, "length":25.75 },
        "MED":{ "width":17.25, "length":26.25 },
        "LRG":{ "width":18.25, "length":26.88 },
        "XLG":{ "width":19.25, "length":27.5 },
        "XXL":{ "width":20.25, "length":28.13 }
      },
      {
        "styleId":"B6500",
        "name":"Bella Jersey Long-Sleeve Crew",
        "SML":{ "width":17, "length":25.75 },
        "MED":{ "width":17.75, "length":26 },
        "LRG":{ "width":18.75, "length":26.88 },
        "XLG":{ "width":19.75, "length":27.5 },
        "XXL":{ "width":20.75, "length":28.13 }
      },
      {
        "styleId":"6004",
        "name":"Bella Ladies Favorite T-Shirt",
        "SML":{ "width":16, "length":26.25 },
        "MED":{ "width":16.75, "length":26.75 },
        "LRG":{ "width":17.75, "length":27.25 },
        "XLG":{ "width":18.75, "length":28 },
        "XXL":{ "width":19.75, "length":28.5 }
      },
      {
        "styleId":"1080",
        "name":"Bella Wide Strap Tank",
        "SML":{ "width":12.25, "length":26.25 },
        "MED":{ "width":13, "length":26.75 },
        "LRG":{ "width":14, "length":27.38 },
        "XLG":{ "width":15, "length":28 },
        "XXL":{ "width":16, "length":28.63 }
      },
      {
        "styleId":"B4070",
        "name":"Bella Racerback Tank",
        "SML":{ "width":11, "length":26 },
        "MED":{ "width":11.5, "length":27 },
        "LRG":{ "width":12, "length":28 },
        "XLG":{ "width":12.5, "length":29 },
        "XXL":{ "width":13.5, "length":30 }
      },
      {
        "styleId":"8430",
        "name":"Bella Sheer Triblend Racerback",
        "XSML":{ "width":15.62, "length":26.5 },
        "SML":{ "width":16.12, "length":26.5 },
        "MED":{ "width":17.25, "length":27 },
        "LRG":{ "width":18.37, "length":27.625 },
        "XLG":{ "width":19.87, "length":28.25 },
        "XXL":{ "width":21.37, "length":28.875 }
      }
    ]
  },
  "canvas":{
    "sizes":[
      {
        "styleId":"3001C",
        "name":"Canvas Unisex T-Shirt - unisex",
        "SML":{ "width":16.5, "length":27 },
        "MED":{ "width":18, "length":28 },
        "LRG":{ "width":20, "length":29 },
        "XLG":{ "width":22, "length":30 },
        "XXL":{ "width":24, "length":31 },
        "XXXL":{ "width":26, "length":32 },
        "XXXXL":{ "width":28, "length":33 },
        "XXXXXL":{ "width":30, "length":34 }
      },
      {
        "styleId":"3501",
        "name":"Canvas Long-Sleeve T - unisex",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 }
      },
      {
        "styleId":"3005",
        "name":"Canvas V-Neck T-Shirt - unisex",
        "SML":{ "width":18, "length":27.75 },
        "MED":{ "width":20, "length":28.75 },
        "LRG":{ "width":22, "length":29.75 },
        "XLG":{ "width":24, "length":30.75 },
        "XXL":{ "width":26, "length":31.75 }
      },
      {
        "styleId":"3400C",
        "name":"Canvas Mens Rib Tank",
        "SML":{ "width":12.25, "length":28.75 },
        "MED":{ "width":14.25, "length":29.75 },
        "LRG":{ "width":16.25, "length":30.75 },
        "XLG":{ "width":18.25, "length":31.75 },
        "XXL":{ "width":20.25, "length":32.75 }
      },
      {
        "styleId":"3901",
        "name":"Canvas Triblend Sweatshirt - Crew",
        "XSML":{ "width":18.5, "length":26.5 },
        "SML":{ "width":19.5, "length":27.75 },
        "MED":{ "width":20.5, "length":29 },
        "LRG":{ "width":21.5, "length":30 },
        "XLG":{ "width":22.75, "length":21 },
        "XXL":{ "width":24, "length":32 },
        "XXXL":{ "width":24 }
      },
      {
        "styleId":"3909",
        "name":"Canvas Triblend Zip Hoodie",
        "XSML":{ "width":17, "length":26 },
        "SML":{ "width":18.5, "length":26.88 },
        "MED":{ "width":20.5, "length":28 },
        "LRG":{ "width":22.5, "length":29.25 },
        "XLG":{ "width":24.5, "length":30.5 },
        "XXL":{ "width":26.5, "length":31.75 }
      },
      {
        "styleId":"3710",
        "name":"Canvas La Brea Piped Jacket",
        "SML":{ "width":20, "length":27 },
        "MED":{ "width":21, "length":28 },
        "LRG":{ "width":22, "length":29 },
        "XLG":{ "width":23, "length":30 },
        "XXL":{ "width":24, "length":31 }
      }
    ]
  },
  "champion":{
    "sizes":[
      {
        "styleId":"T425",
        "name":"Champion Tagless T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":31.5 },
        "XXXL":{ "width":28, "length":32 }
      },
      {
        "styleId":"CC8C",
        "name":"Champion Long Sleeve T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":31.5 },
        "XXXL":{ "width":28, "length":32 }
      },
      {
        "styleId":"S600",
        "name":"Champion Sweatshirt",
        "SML":{ "width":20, "length":27 },
        "MED":{ "width":22, "length":28 },
        "LRG":{ "width":24, "length":29 },
        "XLG":{ "width":26, "length":30 },
        "XXL":{ "width":28, "length":31 },
        "XXXL":{ "width":30, "length":32 }
      }
    ]
  },
  "code-v":{
    "sizes":[
      {
        "styleId":"3980",
        "name":"Code V REALTREE Camo T-shirt",
        "SML":{ "width":18, "length":27.75 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30.75 },
        "XLG":{ "width":24, "length":31.5 },
        "XXL":{ "width":26, "length":32.5 },
        "XXXL":{ "width":28, "length":33.5 }
      }
    ]
  },
  "fruit-of-the-loom":{
    "sizes":[
      {
        "styleId":"3931",
        "name":"Fruit of the Loom Cotton T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 },
        "XXXXXL":{ "width":32, "length":35 },
        "XXXXXXL":{ "width":34, "length":36 }
      },
      {
        "styleId":"L3930R",
        "name":"Fruit of the Loom Ladies Heavy Cotton T-Shirt",
        "SML":{ "width":18, "length":25 },
        "MED":{ "width":19, "length":26 },
        "LRG":{ "width":20.5, "length":27 },
        "XLG":{ "width":22, "length":28 },
        "XXL":{ "width":23.5, "length":29 },
        "XXXL":{ "width":25, "length":30 }
      },
      {
        "styleId":"4930",
        "name":"Fruit of the Loom Long Sleeve",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 }
      },
      {
        "styleId":"3931B",
        "name":"Fruit of the Loom Youth Cotton T",
        "XSML":{ "width":14, "length":18 },
        "SML":{ "width":16, "length":20 },
        "MED":{ "width":17, "length":22 },
        "LRG":{ "width":18, "length":24 }
      }
    ]
  },
  "gildan":{
    "sizes":[
      {
        "styleId":"5000",
        "name":"Gildan Cotton T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 },
        "XXXXXL":{ "width":32, "length":35 }
      },
      {
        "styleId":"2000",
        "name":"Gildan Ultra Cotton T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 },
        "XXXXXL":{ "width":32, "length":35 }
      },
      {
        "styleId":"5000B",
        "name":"Gildan Youth Cotton T",
        "XSML":{ "width":16, "length":20.5 },
        "SML":{ "width":17, "length":22 },
        "MED":{ "width":18, "length":23.5 },
        "LRG":{ "width":19, "length":25 },
        "XLG":{ "width":20, "length":26.5 }
      },
      {
        "styleId":"2400",
        "name":"Gildan Long Sleeve T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 },
        "XXXXXL":{ "width":32, "length":35 }
      },
      {
        "styleId":"2000T",
        "name":"Gildan Ultra Cotton Tall T",
        "XLG":{ "width":24, "length":33.4 },
        "XXL":{ "width":26, "length":34.5 },
        "XXXL":{ "width":28, "length":35.5 },
        "LRG":{
          "length":32.5
        }
      },
      {
        "styleId":"2300",
        "name":"Gildan Ultra Pocket T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 },
        "XXXXXL":{ "width":32, "length":35 }
      },
      {
        "styleId":"2410",
        "name":"Gildan Long Sleeve Pocket T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 }
      },
      {
        "styleId":"2000B",
        "name":"Gildan Youth Ultra Cotton T",
        "XSML":{ "width":16, "length":20.5 },
        "SML":{ "width":17, "length":22 },
        "MED":{ "width":18, "length":23.5 },
        "LRG":{ "width":19, "length":25 },
        "XLG":{ "width":20, "length":26.5 }
      },
      {
        "styleId":"2400B",
        "name":"Gildan Youth Ultra Cotton Long-Sleeve T-Shirt",
        "SML":{ "width":17, "length":22 },
        "MED":{ "width":18, "length":23.5 },
        "LRG":{ "width":19, "length":25 },
        "XLG":{ "width":20, "length":26.5 }
      },
      {
        "styleId":"G64V",
        "name":"Gildan Softstyle V-Neck T-Shirt",
        "SML":{ "width":18, "length":27 },
        "MED":{ "width":20, "length":28 },
        "LRG":{ "width":22, "length":29 },
        "XLG":{ "width":24, "length":30 },
        "XXL":{ "width":26, "length":31 },
        "XXXL":{ "width":28, "length":32 }
      },
      {
        "styleId":"G64VL",
        "name":"Gildan Ladies V-Neck",
        "SML":{ "width":16, "length":25.25 },
        "MED":{ "width":17, "length":26.25 },
        "LRG":{ "width":18.5, "length":27.25 },
        "XLG":{ "width":19.5, "length":28 },
        "XXL":{ "width":22, "length":28.5 },
        "XXXL":{ "width":23.5, "length":29 }
      },
      {
        "styleId":"G270",
        "name":"Gildan Sleeveless T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 }
      },
      {
        "styleId":"G642L",
        "name":"Gildan Ladies Contoured Tank",
        "SML":{ "width":16, "length":25.25 },
        "MED":{ "width":17, "length":26.25 },
        "LRG":{ "width":18.5, "length":27.25 },
        "XLG":{ "width":19.5, "length":28 },
        "XXL":{ "width":22, "length":28.5 },
        "XXXL":{ "width":23.5, "length":29 }
      },
      {
        "styleId":"2200",
        "name":"Gildan Ultra Cotton Tank",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 }
      },
      {
        "styleId":"18500",
        "name":"Gildan 50/50 Hoodie",
        "SML":{ "width":20, "length":26 },
        "MED":{ "width":22, "length":27 },
        "LRG":{ "width":24, "length":28 },
        "XLG":{ "width":26, "length":29 },
        "XXL":{ "width":28, "length":30 },
        "XXXL":{ "width":30, "length":31 },
        "XXXXL":{ "width":32, "length":32 },
        "XXXXXL":{ "width":34, "length":33 }
      },
      {
        "styleId":"18000",
        "name":"Gildan Sweatshirt - Crew",
        "SML":{ "width":20, "length":26 },
        "MED":{ "width":22, "length":27 },
        "LRG":{ "width":24, "length":28 },
        "XLG":{ "width":26, "length":29 },
        "XXL":{ "width":28, "length":30 },
        "XXXL":{ "width":30, "length":31 },
        "XXXXL":{ "width":32, "length":32 },
        "XXXXXL":{ "width":34, "length":33 }
      },
      {
        "styleId":"12000",
        "name":"Gildan Heavy Sweatshirt",
        "SML":{ "width":20, "length":26 },
        "MED":{ "width":22, "length":27 },
        "LRG":{ "width":24, "length":28 },
        "XLG":{ "width":26, "length":29 },
        "XXL":{ "width":28, "length":30 },
        "XXXL":{ "width":30, "length":31 }
      },
      {
        "styleId":"18600",
        "name":"Gildan Zip Front Hoodie",
        "SML":{ "width":19.25, "length":26 },
        "MED":{ "width":21.25, "length":27 },
        "LRG":{ "width":23.25, "length":28 },
        "XLG":{ "width":25.25, "length":29 },
        "XXL":{ "width":27.25, "length":30 },
        "XXXL":{ "width":29.25, "length":31 },
        "XXXXL":{ "width":31.25, "length":32 },
        "XXXXXL":{ "width":32.25, "length":33 }
      },
      {
        "styleId":"18500b",
        "name":"Gildan Youth Hoodie",
        "XSML":{ "width":16, "length":19.75 },
        "SML":{ "width":17, "length":21.25 },
        "MED":{ "width":18, "length":22.5 },
        "LRG":{ "width":19, "length":24 },
        "XLG":{ "width":20, "length":25.5 }
      },
      {
        "styleId":"18000b",
        "name":"Gildan Youth Sweatshirt",
        "XSML":{ "width":16, "length":19.75 },
        "SML":{ "width":17, "length":21.25 },
        "MED":{ "width":18, "length":22.5 },
        "LRG":{ "width":19, "length":24 },
        "XLG":{ "width":20, "length":25.5 }
      },
      {
        "styleId":"G126",
        "name":"Gildan Heavy Full-Zip Hoodie",
        "SML":{ "width":26, "length":19.25 },
        "MED":{ "width":27, "length":21.25 },
        "LRG":{ "width":28, "length":23.25 },
        "XLG":{ "width":29, "length":25.25 },
        "XXL":{ "width":30, "length":27.25 },
        "XXXL":{ "width":31, "length":29.25 }
      },
      {
        "styleId":"G186FL",
        "name":"Gildan Ladies Zip Hood",
        "SML":{ "width":19, "length":24.5 },
        "MED":{ "width":21, "length":25.5 },
        "LRG":{ "width":23, "length":26.5 },
        "XLG":{ "width":25, "length":27 },
        "XXL":{ "width":27, "length":27.5 },
        "XXXL":{ "width":29, "length":28 }
      },
      {
        "styleId":"G380L",
        "name":"Gildan Ladies Ultra cotton pique-polo",
        "SML":{ "width":19, "length":25 },
        "MED":{ "width":21, "length":26 },
        "LRG":{ "width":23, "length":27 },
        "XLG":{ "width":25, "length":28 },
        "XXL":{ "width":27, "length":28.5 }
      },
      {
        "styleId":"G380",
        "name":"Gildan Men's Ultra cotton pique-polo",
        "SML":{ "width":19, "length":28.5 },
        "MED":{ "width":21, "length":30 },
        "LRG":{ "width":23, "length":31 },
        "XLG":{ "width":25, "length":32 },
        "XXL":{ "width":27, "length":33 },
        "XXXL":{ "width":29, "length":34 },
        "XXXXL":{ "width":31, "length":35 },
        "XXXXXL":{ "width":33, "length":36 }
      },
      {
        "styleId":"G187",
        "name":"Gildan Vintage Zip Hood",
        "SML":{ "width":19.25, "length":26 },
        "MED":{ "width":21.25, "length":27 },
        "LRG":{ "width":23.25, "length":28 },
        "XLG":{ "width":25.25, "length":29 },
        "XXL":{ "width":27.25, "length":30 },
        "XXXL":{ "width":29.25, "length":31 }
      }
    ]
  },
  "hanes":{
    "sizes":[
      {
        "styleId":"5250",
        "name":"Hanes Tagless T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":33 },
        "XXXL":{ "width":28, "length":34 },
        "XXXXL":{ "width":30, "length":35 },
        "XXXXXL":{ "width":32, "length":36 },
        "XXXXXXL":{ "width":35, "length":37 }
      },
      {
        "styleId":"5680",
        "name":"Hanes Ladies ComfortSoft T",
        "SML":{ "width":18, "length":25 },
        "MED":{ "width":20, "length":26 },
        "LRG":{ "width":22, "length":27.5 },
        "XLG":{ "width":24, "length":29 },
        "XXL":{ "width":26, "length":30 },
        "XXXL":{ "width":28, "length":31 }
      },
      {
        "styleId":"5180",
        "name":"Hanes Beefy-T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":33 },
        "XXXL":{ "width":28, "length":34 },
        "XXXXL":{ "width":30, "length":35 },
        "XXXXXL":{ "width":32, "length":36 },
        "XXXXXXL":{ "width":36, "length":37 }
      },
      {
        "styleId":"5190",
        "name":"Hanes Beefy Pocket T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":33 },
        "XXXL":{ "width":28, "length":34 }
      },
      {
        "styleId":"5186",
        "name":"Hanes Long-Sleeve Beefy-T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":33 },
        "XXXL":{ "width":28, "length":34 }
      },
      {
        "styleId":"5586",
        "name":"Hanes Long Sleeve Tagless",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":33 },
        "XXXL":{ "width":28, "length":34 }
      },
      {
        "styleId":"5380",
        "name":"Hanes Youth Beefy-T",
        "XSML":{ "width":15, "length":19 },
        "SML":{ "width":16, "length":20.75 },
        "MED":{ "width":17, "length":23.5 },
        "LRG":{ "width":19, "length":24.75 },
        "XLG":{ "width":20, "length":27 }
      },
      {
        "styleId":"5596",
        "name":"Hanes Long Sleeve Pocket T",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":33 },
        "XXXL":{ "width":28, "length":34 }
      },
      {
        "styleId":"SL04",
        "name":"Hanes Ladies Nano T-Shirt",
        "SML":{ "width":18.5, "length":25.5 },
        "MED":{ "width":19.5, "length":26 },
        "LRG":{ "width":21, "length":26.75 },
        "XLG":{ "width":22.75, "length":27.5 },
        "XXL":{ "width":24.75, "length":28 }
      },
      {
        "styleId":"5780",
        "name":"Hanes Ladies V-neck Cotton T-Shirt",
        "SML":{ "width":18, "length":25 },
        "MED":{ "width":20, "length":26 },
        "LRG":{ "width":22, "length":27.5 },
        "XLG":{ "width":24, "length":29 },
        "XXL":{ "width":26, "length":30 },
        "XXXL":{ "width":28, "length":31 }
      },
      {
        "styleId":"P170",
        "name":"Hanes 50/50 Hoodie",
        "SML":{ "width":20, "length":26.5 },
        "MED":{ "width":22, "length":27.5 },
        "LRG":{ "width":24, "length":28.5 },
        "XLG":{ "width":26, "length":29.5 },
        "XXL":{ "width":28, "length":30 },
        "XXXL":{ "width":30, "length":30.5 },
        "XXXXL":{ "width":32, "length":31 }
      },
      {
        "styleId":"P180",
        "name":"Hanes Zip Front Hoodie",
        "SML":{ "width":20, "length":26.5 },
        "MED":{ "width":22, "length":27.5 },
        "LRG":{ "width":24, "length":28.5 },
        "XLG":{ "width":26, "length":29.5 },
        "XXL":{ "width":28, "length":30 },
        "XXXL":{ "width":30, "length":30.5 }
      },
      {
        "styleId":"P473",
        "name":"Hanes Youth 50/50 Hoodie",
        "SML":{ "width":14, "length":18 },
        "MED":{ "width":16, "length":20.5 },
        "LRG":{ "width":17, "length":23 },
        "XLG":{ "width":18, "length":25 },
        "XXL":{ "width":20, "length":26.5 }
      },
      {
        "styleId":"P1607",
        "name":"Hanes 50/50 Fleece Crew",
        "SML":{ "width":20, "length":26.5 },
        "MED":{ "width":22, "length":27.5 },
        "LRG":{ "width":24, "length":28.5 },
        "XLG":{ "width":26, "length":29.5 },
        "XXL":{ "width":28, "length":30 },
        "XXXL":{ "width":30, "length":30.5 },
        "XXXXL":{ "width":32, "length":31 },
        "XXXXXL":{ "width":34, "length":31.5 }
      },
      {
        "styleId":"F260",
        "name":"Hanes Ultimate Cotton Fleece Crew",
        "SML":{ "width":21, "length":28 },
        "MED":{ "width":23, "length":29 },
        "LRG":{ "width":25, "length":30 },
        "XLG":{ "width":27, "length":31 },
        "XXL":{ "width":29, "length":32 },
        "XXXL":{ "width":31, "length":33 }
      },
      {
        "styleId":"F280",
        "name":"Hanes Ultimate Cotton Zip Hood",
        "SML":{ "width":21, "length":28 },
        "MED":{ "width":23, "length":29 },
        "LRG":{ "width":25, "length":30 },
        "XLG":{ "width":27, "length":31 },
        "XXL":{ "width":29, "length":32 },
        "XXXL":{ "width":31, "length":33 }
      },
      {
        "styleId":"F170",
        "name":"Hanes Ultimate Cotton Hoodie",
        "SML":{ "width":21, "length":28 },
        "MED":{ "width":23, "length":29 },
        "LRG":{ "width":25, "length":30 },
        "XLG":{ "width":27, "length":31 },
        "XXL":{ "width":29, "length":32 },
        "XXXL":{ "width":31, "length":33 }
      }
    ]
  },
  "jerzees":{
    "sizes":[
      {
        "styleId":"29M",
        "name":"Jerzees 50/50 T-Shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 },
        "XXXXXL":{ "width":32, "length":35 }
      },
      {
        "styleId":"29LS",
        "name":"Jerzees 50/50 Long Sleeve",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 }
      },
      {
        "styleId":"29B",
        "name":"Jerzees Youth 50/50 T-Shirt",
        "XSML":{ "width":14, "length":18 },
        "SML":{ "width":16, "length":20 },
        "MED":{ "width":17, "length":22 },
        "LRG":{ "width":18, "length":24 },
        "XLG":{ "width":19, "length":26 }
      },
      {
        "styleId":"29BL",
        "name":"Jerzees Youth 50/50 Heavy Blend Long-Sleeve T-Shirt",
        "SML":{ "width":16, "length":20 },
        "MED":{ "width":17, "length":22 },
        "LRG":{ "width":18, "length":24 }
      },
      {
        "styleId":"996M",
        "name":"Jerzees 50/50 Hoodie",
        "SML":{ "width":20, "length":27 },
        "MED":{ "width":22, "length":28 },
        "LRG":{ "width":24, "length":29 },
        "XLG":{ "width":26, "length":30 },
        "XXL":{ "width":28, "length":31 },
        "XXXL":{ "width":30, "length":32 },
        "XXXXL":{ "width":32, "length":33 }
      },
      {
        "styleId":"4662M",
        "name":"Jerzees Sweatshirt - Crew",
        "SML":{ "width":20, "length":27 },
        "MED":{ "width":22, "length":28 },
        "LRG":{ "width":24, "length":29 },
        "XLG":{ "width":26, "length":30 },
        "XXL":{ "width":28, "length":31 },
        "XXXL":{ "width":30, "length":32 },
        "XXXXL":{ "width":32, "length":33 }
      },
      {
        "styleId":"993M",
        "name":"Jerzees Zip Front Hoodie",
        "SML":{ "width":20, "length":27 },
        "MED":{ "width":22, "length":28 },
        "LRG":{ "width":24, "length":29 },
        "XLG":{ "width":26, "length":30 },
        "XXL":{ "width":28, "length":31 },
        "XXXL":{ "width":30, "length":32 }
      },
      {
        "styleId":"996Y",
        "name":"Jerzees Youth 50/50 Hoodie",
        "SML":{ "width":15, "length":19 },
        "MED":{ "width":17, "length":22 },
        "LRG":{ "width":19, "length":25 },
        "XLG":{ "width":20, "length":28 }
      },
      {
        "styleId":"562B",
        "name":"Jerzees Youth Sweatshirt",
        "SML":{ "width":15, "length":19 },
        "MED":{ "width":17, "length":22 },
        "LRG":{ "width":19, "length":25 },
        "XLG":{ "width":20, "length":28 }
      },
      {
        "styleId":"4528",
        "name":"Jerzees Quarter-Zip Sweater",
        "SML":{ "width":20, "length":27 },
        "MED":{ "width":22, "length":28 },
        "LRG":{ "width":24, "length":29 },
        "XLG":{ "width":26, "length":30 },
        "XXL":{ "width":28, "length":31 },
        "XXXL":{ "width":30, "length":32 }
      }
    ]
  },
  "lat":{
    "sizes":[
      {
        "styleId":"3580",
        "name":"LAT Ladies Ringspun Jersey T-shirt",
        "SML":{ "width":18.38, "length":25 },
        "MED":{ "width":19.38, "length":26 },
        "LRG":{ "width":21.5, "length":27 },
        "XLG":{ "width":23.63, "length":28 },
        "XXL":{ "width":25.75, "length":29 },
        "XXXL":{ "width":27.88, "length":30.5 }
      },
      {
        "styleId":"3616",
        "name":"LAT Ladies Junior Fine Jersey Longer Length T-Shirt",
        "SML":{ "width":15.5, "length":25 },
        "MED":{ "width":16.5, "length":26 },
        "LRG":{ "width":17.5, "length":27 },
        "XLG":{ "width":19, "length":28 },
        "XXL":{ "width":20.5, "length":29 }
      },
      {
        "styleId":"3607",
        "name":"LAT Junior V-Neck",
        "SML":{ "width":15, "length":25 },
        "MED":{ "width":16, "length":26 },
        "LRG":{ "width":17, "length":27 },
        "XLG":{ "width":18.5, "length":28 },
        "XXL":{ "width":20, "length":29 }
      }
    ]
  },
  "next-level":{
    "sizes":[
      {
        "styleId":"3600",
        "name":"Next Level Fitted Crew",
        "XSML":{ "width":17.5, "length":27 },
        "SML":{ "width":19, "length":28 },
        "MED":{ "width":20.5, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 },
        "XXXXL":{ "width":30, "length":34 }
      },
      {
        "styleId":"6010",
        "name":"Next Level Tri Blend Crewneck",
        "SML":{ "width":18.25, "length":27.5 },
        "MED":{ "width":19.75, "length":28.5 },
        "LRG":{ "width":21.25, "length":29.5 },
        "XLG":{ "width":22.5, "length":30.5 },
        "XXL":{ "width":24.75, "length":31.5 },
        "XXXL":{ "width":26.75, "length":32.5 }
      }
    ]
  },
  "tie-dye":{
    "sizes":[
      {
        "styleId":"8777",
        "name":"Tie Dye Adult Tie Dyed Spider Hoodie",
        "SML":{ "width":23, "length":25 },
        "MED":{ "width":24, "length":27 },
        "LRG":{ "width":25, "length":28 },
        "XLG":{ "width":26, "length":30 },
        "XXL":{ "width":28, "length":31 },
        "XXXL":{ "width":30, "length":32 }
      },
      {
        "styleId":"C2000",
        "name":"Tie Dye Unisex Tie Dyed Long Sleeve T-shirt",
        "SML":{ "width":18, "length":28 },
        "MED":{ "width":20, "length":29 },
        "LRG":{ "width":22, "length":30 },
        "XLG":{ "width":24, "length":31 },
        "XXL":{ "width":26, "length":32 },
        "XXXL":{ "width":28, "length":33 }
      },
      {
        "styleId":"H1000",
        "name":"Tie Dye Adult Tie-Dyed Cotton Tee",
        "SML":{ "width":17, "length":25 },
        "MED":{ "width":20, "length":26 },
        "LRG":{ "width":21, "length":27.5 },
        "XLG":{ "width":22, "length":29 },
        "XXL":{ "width":23, "length":30 },
        "XXXL":{ "width":25, "length":31 },
        "XXXXL":{ "width":26, "length":32 },
        "XXXXXL":{ "width":29, "length":33 }
      }
    ]
  },
  "harriton":{
    "sizes":[
      {
        "styleId":"M265W",
        "name":"Harriton Ladies 5.6 oz. Easy Blend Polo",
        "SML":{ "width":17, "length":23.5 },
        "MED":{ "width":18.25, "length":24 },
        "LRG":{ "width":19.5, "length":24.5 },
        "XLG":{ "width":21, "length":25.75 },
        "XXL":{ "width":23.5, "length":27 },
        "XXXL":{ "width":26.5, "length":29.5 },
        "XXXXL":{ "width":28.5, "length":30.5 }
      }
    ]
  }
};